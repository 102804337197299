import "./event.css";

import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import { getFechaFmt, getHoraFmt } from "./../../utils/fechas";

import ControlAcceso from "../../components/controlAcceso/controlacceso";
import InfoEvento from "../../components/InfoEvento/infoEvento";
import Reserva from "../../components/Reserva/reserva";
import SelectorIdioma from "../../components/SelectorIdioma/SelectorIdioma";
import entrances from "../../services/entrances";
import eventosService from "../../services/eventos";
import reservas from "../../services/reservas";
import { useTranslation } from "react-i18next";
import zaragozaJunio from "../../assets/3junioZaragoza.png";
import vitoriaJunio from "../../assets/5jun1.png";
import { Helmet } from "react-helmet";

function Evento({ eventoID }) {
  let location = useLocation();
  let history = useHistory();
  //console.log(location)
  if (location.state !== undefined) {
    var bonos = location.state.bono;
    var user = location.state.user;
    //var entrance = location.state.entrance
  }

  // console.log(user);
  const [evento, setEvento] = useState(null);
  const [loading, setLoading] = useState(true);
  const [t, i18n] = useTranslation("global");
  const [agotado, setAgotado] = useState(true);
  const [canjeado, setCanjeado] = useState(false);
  const [mostrarEntradas, setMostrarEntradas] = useState(true);
  const [entradas_canjeadas, setentradasCanjeadas] = useState(null);
  const [error_mensaje, setErrorMensaje] = useState("");
  const [sesion, setSesion] = useState(null);
  var bono3 = "614da163bfe4da000a616cf7";
  var bono5 = "614da163c9e964000a40c1d8";

  useEffect(() => {
    async function getInfoEvento(eventoID) {
      const evento = await eventosService.getEvento(eventoID);
      //console.log(evento.evento)
      evento.dia_fmt = getFechaFmt(evento.evento.startDate, i18n.language);
      let minutes;
      let hora = new Date(evento.evento.startDate).getHours();
      if (new Date(evento.evento.startDate).getMinutes() < 10) {
        minutes = "0" + new Date(evento.evento.startDate).getMinutes();
      } else {
        minutes = new Date(evento.evento.startDate).getMinutes();
      }
      evento.evento.hora_fmt = hora + ":" + minutes;

      evento.hora_fmt = getHoraFmt(evento.evento.startDate, i18n.language);
      evento.inicio = getFechaFmt(evento.evento.startDate, i18n.language);
      evento.fin = getFechaFmt(evento.evento.endDate, i18n.language);
      evento.tickets.forEach((element) => {
        element.hora_fmt = getHoraFmt(element.startDate, i18n.language);
        element.fecha_fmt = getFechaFmt(element.startDate, i18n.language);
      });
      setEvento(evento);

      let user = null;
      if (location.state !== undefined) {
        user = location.state.user;
        //setBono(location.state.entrance)
      }
      for (const ticket of evento.tickets) {
        if (ticket.amount > ticket.sellTickets) {
          setAgotado(false);
        }
      }
      if (evento.tickets.length > 0) {
        if (evento.tickets[0].name === "Bono") {
          setMostrarEntradas(false);
        }
      }

      if (loading) {
        setLoading(false);
      }
    }
    getInfoEvento(eventoID);
    //console.log(evento);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventoID, loading, i18n.language]);

  const eventoData = {
    iconMetroLocation:
      "https://anima-uploads.s3.amazonaws.com/projects/608138be200c27f99805dc8c/releases/60828d4c841fac091e8381dd/img/icon-metro-location@1x.png",
    overlapGroup13:
      "https://anima-uploads.s3.amazonaws.com/projects/608138be200c27f99805dc8c/releases/60828d4c841fac091e8381dd/img/rectangle-166@1x.png",
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const modalError = () => {
    return;
  };
  return (
    <div className="imageBackGround">
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Container className="evento" style={{ padding: "0" }}>
          {/**BANNER EVENTO */}
          <Helmet>
            <meta charset="utf-8" />
            <title>Dreaming Tickets - Evento {evento.evento.name}</title>
            <meta
              name="description"
              content={evento.evento.description.split("#")[1].slice(0, 160)}
            />
            <link
              rel="canonical"
              href="https://dreamingticket.web.app/ciclo/entradas"
            />
          </Helmet>

          <Row style={{ height: "35px" }}>
            <Col
              style={{
                position: "absolute",
                right: "0%",
                justifyContent: "flex-start",
                display: "flex",
                alignItems: "center",
                backgroundColor: "black",
              }}
            >
              <SelectorIdioma />
            </Col>
          </Row>
          {/*  <Row style={{ height: "73px", width: "100%" }}>
            <Col
              xs={3}
              style={{
                marginRight: "30px",
              }}
            >
              <SelectorIdioma />
            </Col>
            <Col xs={8} style={{ justifyContent: "flex-end", display: "flex" }}>
              <Col sm={1} xl={3}>
                <img style={{ width: "130px" }} src={logo1} alt="banner" />
              </Col>
              <Col
                xs={3}
                style={{
                  padding: "0",
                  marginLeft: "10px",
                  marginRight: "-22px",
                }}
              >
                <img style={{ width: "235px" }} src={logo2} alt="banner" />
              </Col>
            </Col>
          </Row> */}
          {evento.evento.location === "SALA LO INTENTO (Zaragoza)" ? (
            <Row style={{ width: "100%" }}>
              <img className="bannerZaragoza" src={zaragozaJunio} alt="banner" />
            </Row>
          ) : (
            <>
              {evento.evento.location === "Urban Rock Concept (Vitoria)" ?
                (
                  <Row
                    style={{ width: "100%" }}
                  >
                    <img className="bannerZaragoza" src={vitoriaJunio} alt="banner" />
                  </Row>
                ) : (
                  <Row
                  style={{ width: "100%",display:"contents" }}
                  >
                    <img className="eventoBanner" src={evento.evento.banner} alt="banner" />
                  </Row>
                )}
            </>
          )}

          <Row
            style={{
              justifyContent: "center",
              width: "100%",
              height: "maxContent",
              margin: "0",
            }}
          >
            <div className="resumenEven">
              <h2 className="text-4Even">{evento.evento.name}</h2>
              <div className="group-550Even">
                <img
                  className="icon-metro-locationEven"
                  src={eventoData.iconMetroLocation}
                  alt=""
                />

                {evento.evento.ubication.location === undefined ? (
                  <div className="text-6Even montserrat-regular-normal-thunder-30px">
                    {evento.evento.location}
                  </div>
                ) : (
                  <div className="text-6Even montserrat-regular-normal-thunder-30px">
                    {evento.evento.ubication.location}
                  </div>
                )}
              </div>
              {evento.tickets.length === 0 ? (
                <div className="text-5Even montserrat-regular-normal-thunder-30px">
                  {evento.dia_fmt} {evento.evento.hora_fmt}
                </div>
              ) : (
                <div>
                  {evento.tickets.length > 1 ? (
                    <>
                      {evento.tickets[0].hora_fmt ===
                        evento.tickets[1].hora_fmt ? (
                        <div className="text-5Even montserrat-regular-normal-thunder-30px">
                          {evento.dia_fmt} {evento.evento.hora_fmt}
                        </div>
                      ) : (
                        <div className="text-5Even montserrat-regular-normal-thunder-30px">
                          {evento.dia_fmt} {evento.tickets[0].hora_fmt}
                          {" / " + evento.tickets[1].hora_fmt}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="text-5Even montserrat-regular-normal-thunder-30px">
                      {evento.dia_fmt} {evento.tickets[0].hora_fmt}
                    </div>
                  )}
                </div>
              )}

              {location.pathname.includes("entradas") ||
                location.pathname.includes("controlacceso") ||
                evento.tickets.length === 0 ||
                evento.tickets[0].name === "Bono" ? (
                <div style={{ marginTop: "20px" }}>
                  {bonos ? (
                    <div>
                      {evento.tickets.length > 1 ? (
                        <Form>
                          <Form.Control
                            as="select"
                            custom
                            onChange={(e) => {
                              //console.log(e.target.value)
                              if (e.target.value !== "Elige la sesion") {
                                setSesion(evento.tickets[e.target.value]);
                              } else {
                                setSesion(null);
                              }
                            }}
                          >
                            <option value={null}>Elige la sesion</option>
                            {evento.tickets.map((data, key) => {
                              console.log(data);
                              return (
                                <option key={key} value={key}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form>
                      ) : (
                        ""
                      )}
                      {sesion === null && evento.tickets.length > 1 ? (
                        <Button disabled>Canjear Entrada</Button>
                      ) : (
                        <Button
                          onClick={async () => {
                            setLoading(true);
                            let bonos_activos = [];
                            await entrances
                              .checkBonos([bono3, bono5], user._id)
                              .then((res) => {
                                res.forEach((element) => {
                                  if (element.number_used === null) {
                                    bonos_activos.push(element);
                                  } else {
                                    if (
                                      element.ticket === bono3 &&
                                      element.number_used < 3
                                    ) {
                                      bonos_activos.push(element);
                                    }

                                    if (
                                      element.ticket === bono5 &&
                                      element.number_used < 5
                                    ) {
                                      bonos_activos.push(element);
                                    }
                                  }
                                });
                              });

                            if (bonos_activos.length === 0) {
                              setErrorMensaje(
                                "No tienes mas bonos para canjear"
                              );
                              setShow(true);
                            } else {
                              let ticketsList = [
                                {
                                  email: user.correoElectronico,
                                  ticket: evento.tickets[0]._id,
                                  companion: null,
                                  book: {
                                    eventKey: null,
                                    seats: null,
                                    hold: null,
                                  },
                                },
                              ];

                              const res_comprobar = await reservas
                                .comprobarEntradas(
                                  user.email,
                                  { ticketsList },
                                  1
                                )
                                .then((resComprarEntrada) => {
                                  //console.log(resComprarEntrada)
                                  if (resComprarEntrada.availability === 0) {
                                    // console.log("No quedan mas entradas disponibles")
                                    setErrorMensaje(
                                      "No quedan mas entradas disponibles"
                                    );
                                  }

                                  if (
                                    resComprarEntrada.amountPerPerson <=
                                    resComprarEntrada.amount
                                  ) {
                                    //console.log("Ya has reservado el maximo de reservas disponibles por persona")
                                    setErrorMensaje(
                                      "Ya has reservado el maximo de reservas disponibles por persona"
                                    );
                                  }

                                  if (resComprarEntrada.availability < 1) {
                                    //console.log("Mas entradas que las disponibles")
                                    setErrorMensaje(
                                      "Mas entradas que las disponibles"
                                    );
                                  }
                                });
                              if (res_comprobar !== "") {
                                const res = await reservas.mandarEntrada(
                                  user.email,
                                  evento.tickets.length > 1
                                    ? sesion._id
                                    : evento.tickets[0]._id
                                );
                                setentradasCanjeadas(res);
                                if (
                                  res.message === "Se ha enviado correctamente"
                                ) {
                                  await entrances
                                    .updateEntrance(
                                      bonos_activos[0]._id,
                                      bonos_activos[0].number_used === null
                                        ? 1
                                        : bonos_activos[0].number_used + 1
                                    )
                                    .then((res) => {
                                      //console.log(res)
                                      if (
                                        res.data.message ===
                                        "Entrance updated succesfully"
                                      ) {
                                        setLoading(false);
                                        setMostrarEntradas(true);
                                        setCanjeado(true);
                                        //console.log(sesion)
                                        history.push(
                                          evento.evento._id + "/entradas"
                                        );
                                      }
                                    });
                                }
                              }
                            }
                          }}
                        >
                          Canjear Entrada
                        </Button>
                      )}
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Error</Modal.Title>
                        </Modal.Header>
                        <ModalBody>{error_mensaje}</ModalBody>
                      </Modal>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <div className="buttonnormal">
                  <div>
                    {evento.tickets[0].grossPrice > 0 ? (
                      <>
                        {agotado ? (
                          <Button
                            class="btn btn-primary"
                            style={{
                              backgroundColor: "white",
                              borderColor: "gray",
                              color: "black",
                            }}
                            disabled
                          >
                            {t("header.agotada")}
                          </Button>
                        ) : (
                          <>
                            {evento.evento.tags[0] ===
                              "Gau magikoa / La noche mágica" ? (
                              <Link
                                to={{
                                  pathname:
                                    "https://eventos.bilbao.ontourup.com/event/" +
                                    evento.evento.qr +
                                    "/tickets",
                                }}
                                target="_blank"
                                replace
                              >
                                <div
                                  className="overlap-group1-2"
                                  style={{
                                    backgroundImage: `url(${eventoData.overlapGroup13})`,
                                  }}
                                >
                                  <div className="reservar-entradas">
                                    {t("header.compra-entradas")}
                                  </div>
                                </div>
                              </Link>
                            ) : (
                              <Link
                                to={{
                                  pathname:
                                    "https://eventos.ontourup.com/event/" +
                                    evento.evento.qr +
                                    "/tickets",
                                }}
                                target="_blank"
                              >
                                <div
                                  className="overlap-group1-2"
                                  style={{
                                    backgroundImage: `url(${eventoData.overlapGroup13})`,
                                  }}
                                >
                                  <div className="reservar-entradas">
                                    {t("header.compra-entradas")}
                                  </div>
                                </div>
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {agotado ? (
                          <Button
                            class="btn btn-primary"
                            style={{
                              backgroundColor: "white",
                              borderColor: "gray",
                              color: "black",
                            }}
                            disabled
                          >
                            {t("header.mensaje-agotado")}
                          </Button>
                        ) : (
                          <Link to={evento.evento._id + "/entradas"}>
                            <div
                              className="overlap-group1-2"
                              style={{
                                backgroundImage: `url(${eventoData.overlapGroup13})`,
                              }}
                            >
                              <div
                                className="reservar-entradas"
                                style={{ lineHeight: "35px" }}
                              >
                                {t("header.reserva-entradas")}
                              </div>
                            </div>
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Row>
          {/**FIN BANNER EVENTO */}

          <Switch>
            <Route exact path="/evento/:eventoId">
              <Container style={{ marginTop: "50px" }}>
                <InfoEvento
                  evento={evento.evento}
                  eventoData={eventoData}
                  tickets={evento.tickets}
                />
              </Container>
            </Route>
            {mostrarEntradas && !loading ? (
              <Route path="/evento/:eventoId/entradas">
                <Reserva
                  ticket={evento.tickets}
                  evento={evento.evento}
                  step={canjeado ? 2 : 0}
                  entradas_canjeadas={entradas_canjeadas}
                  sesion={sesion}
                ></Reserva>
              </Route>
            ) : (
              ""
            )}

            <Route path="/evento/:eventoId/controlacceso">
              <ControlAcceso evento={evento.evento} ticket={evento.tickets} />
            </Route>
          </Switch>
        </Container>
      )}{" "}
    </div>
  );
}

export default Evento;
