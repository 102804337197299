import "./reserva.css";

import * as yup from "yup";

import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { getFechaFmt, getHoraFmt } from "./../../utils/fechas";

import { Link } from "react-router-dom";
import QRCode from "qrcode.react";
import ReservaService from "./../../services/reservas";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { useTranslation } from "react-i18next";

async function hacerReserva(
  correoElectronico,
  dataEntrada,
  dataRegistro,
  setEntradas,
  setLoading,
  setmensajeError,
  setActiveStep,
  amount,
  t,
  sesiones
) {
  try {
    await ReservaService.reservarEntrada(
      correoElectronico,
      dataEntrada,
      dataRegistro,
      amount
    ).then((res) => {
      setEntradas(res);
      setLoading(false);
    });
  } catch (error) {
    //console.log(error.data);
    if (sesiones) {
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }

    switch (error.name /* Esto pasarlo al html para que actualize*/) {
      case "max-entradas":
        setmensajeError(t("header.error-maxentradas"));
        break;

      case "max1entradamas":
        setmensajeError(
          t("header.error-unaentrada") +
            " " +
            error.data.amount +
            t("header.error-unaentrada1") +
            " " +
            error.data.free +
            " " +
            t("header.error-unaentrada2")
        );
        break;
      case "max1entradalibre":
        setmensajeError(
          t("header.error-solounaentrada") +
            " " +
            error.data +
            " " +
            t("header.error-solounaentrada1")
        );

        break;
      case "sinentradas":
        setmensajeError(t("header.agotada"));

        break;
      default:
        setmensajeError(t("header.error-generico"));

        break;
    }
    setLoading(false);
  }
}

function Reserva({ ticket, evento, step, entradas_canjeadas, sesion }) {
  //console.log(sesion)
  //console.log(entradas_canjeadas)
  //console.log(evento);
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_" + evento.name + ".png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  // eslint-disable-next-line no-unused-vars
  const [chart, setChart] = useState();
  const [activeStep, setActiveStep] = useState(step);
  const [t, i18n] = useTranslation("global");
  const steps = [t("header.datos"), t("header.confirmacion")];
  const steps_sesiones = [
    t("header.sesion"),
    t("header.datos"),
    t("header.confirmacion"),
  ];
  const [sesiones, setSesiones] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const textocondiciones = () => {
    return (
      <label>
        {t("header.aceptacion-condiciones")} {t("header.condiciones")}
        {t("header.aceptacion-condiciones-2")}
        {t("header.politica-privacidad-2")}
      </label>
    );
  };
  const [loading, setloading] = useState(true);
  const [entradas, setEntradas] = useState(entradas_canjeadas);
  // eslint-disable-next-line no-unused-vars
  const [venta_activa, setVentaActiva] = useState(false);
  const [sesion_seleccionada, setSesionSeleccionada] = useState(sesion);
  // eslint-disable-next-line no-unused-vars
  const [num_entradas, setNumEntradas] = useState(0);
  const [mensajeError, setmensajeError] = useState("");
  const [cantidad_entradas, setcantidadEntradas] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [asientos, setAsientos] = useState([]);
  const [schema, setSchema] = useState(null);

  /*const book = {
    event: ticket[0].eventkey,
    seats: seats,
    hold: ticket[0].hold,
  }; */

  useEffect(() => {
    if (
      evento.tags.includes("Iralabarri bisitak") ||
      evento.tags.includes("Iralabarri familian")
    ) {
      setSchema(schema_iralabarri);
    } else if (evento.isChart) {
      setSchema(schema_asientos);
    } else if (evento.tags.includes("Ginkana kultural")) {
      setSchema(schema_gymkana);
    } else {
      setSchema(schema_normal);
    }
    evento.fecha_fmt = getFechaFmt(evento.startDate, i18n.language);
    let minutes;
    let hora = new Date(evento.startDate).getHours();
    if (new Date(evento.startDate).getMinutes() < 10) {
      minutes = "0" + new Date(evento.startDate).getMinutes();
    } else {
      minutes = new Date(evento.startDate).getMinutes();
    }
    evento.hora_fmt = hora + ":" + minutes;

    if (ticket.length > 1) {
      setSesiones(true);
    }
    if (ticket.length === 1) {
      setSesionSeleccionada(ticket[0]);
      let j = [];
      var disponibles =
        parseInt(ticket[0].amount) - parseInt(ticket[0].sellTickets);

      if (disponibles > ticket[0].amountPerPerson) {
        for (let i = 0; i <= 2; i++) {
          j.push(
            <option key={i} value={i}>
              {i}
            </option>
          );
        }
        setcantidadEntradas(j);
      } else {
        for (let i = 0; i <= 2; i++) {
          j.push(
            <option key={i} value={i}>
              {i}
            </option>
          );
        }
        setcantidadEntradas(j);
      }
    }

    //console.log(schema);
    var sellDate = getFechaFmt(ticket[0].sellStartDate, i18n.language);
    ticket.forEach((element) => {
      element.fecha_fmt = getFechaFmt(element.startDate, i18n.language);
      if (new Date(element.sellStartDate).getTime() <= new Date().getTime()) {
        element.venta_activa = true;
        element.mensajeventa = t("header.selecciona-entrada");
      } else {
        if (evento.tags.includes("Iralabarri bisitak")) {
          element.mensajeventa = t(
            "header.recomendaciones-entradas-IRALABARRI4"
          );
        } else if (evento.tags.includes("Zabala bisitak")) {
          element.mensajeventa = t("header.recomendaciones-entradas-Zabala");
        } else {
          element.mensajeventa =
            t("header.reserva-activacionBilbao") + sellDate;
        }
      }

      if (new Date().getTime() >= new Date(element.sellEndDate).getTime()) {
        element.venta_activa = false;
        element.mensajeventa = t("header.fin-evento");
      }

      if (element.soldOut === true) {
        element.venta_activa = false;
        element.mensajeventa = t("header.mensaje-agotado");
      }
    });

    if (loading) {
      setloading(false);
    }

    //console.log(sesion_seleccionada)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evento, asientos, categorias]);

  const schema_normal = yup.object().shape({
    nombre: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
        t("header.validacion-solo-letras")
      )
      .min(3, t("header.validacion-letras"))
      .required(t("header.campo-obligatorio")),
    apellidos: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
        t("header.validacion-solo-letras")
      )
      .min(3, t("header.validacion-letras"))
      .required(t("header.campo-obligatorio")),
    telefono: yup
      .string()
      .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
      .min(9, t("header.validacion-telefono-num"))
      .max(9, t("header.validacion-telefono-num"))
      .required(t("header.campo-obligatorio")),
    codigoPostal: yup
      .string()
      .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
      .min(5, t("header.validacion-codigo-postal-num"))
      .max(5, t("header.validacion-codigo-postal-num"))
      .required(t("header.campo-obligatorio")),
    correoElectronico: yup
      .string()
      .email(t("header.formato"))
      .required(t("header.campo-obligatorio")),
    confirmarcorreo: yup
      .string()
      .oneOf([yup.ref("correoElectronico")], t("header.correos-no-coinciden")),
    acompanantes: yup.array().of(
      yup.object().shape({
        nombre_acompanante: yup
          .string()
          .matches(
            /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
            t("header.validacion-solo-letras")
          )
          .min(3, t("header.validacion-letras"))
          .required(t("header.campo-obligatorio")),
        apellido_acompanante: yup
          .string()
          .matches(
            /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
            t("header.validacion-solo-letras")
          )
          .min(3, t("header.validacion-letras"))
          .required(t("header.campo-obligatorio")),
        telefono_acompanante: yup
          .string()
          .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
          .min(9, t("header.validacion-telefono-num"))
          .max(9, t("header.validacion-telefono-num"))
          .required(t("header.campo-obligatorio")),
        postalCode_acompanante: yup
          .string()
          .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
          .min(5, t("header.validacion-codigo-postal-num"))
          .max(5, t("header.validacion-codigo-postal-num"))
          .required(t("header.campo-obligatorio")),
        email_acompanante: yup
          .string()
          .email(t("header.formato"))
          .required(t("header.campo-obligatorio")),
      })
    ),
    condiciones: yup
      .bool()
      .required()
      .oneOf([true], t("header.aceptar-condiciones")),
    num_entradas: yup.number(),
    //seats: yup.array().of(yup.string())
  });

  const schema_asientos = yup.object().shape({
    nombre: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
        t("header.validacion-solo-letras")
      )
      .min(3, t("header.validacion-letras"))
      .required(t("header.campo-obligatorio")),
    apellidos: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
        t("header.validacion-solo-letras")
      )
      .min(3, t("header.validacion-letras"))
      .required(t("header.campo-obligatorio")),
    telefono: yup
      .string()
      .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
      .min(9, t("header.validacion-telefono-num"))
      .max(9, t("header.validacion-telefono-num"))
      .required(t("header.campo-obligatorio")),
    codigoPostal: yup
      .string()
      .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
      .min(5, t("header.validacion-codigo-postal-num"))
      .max(5, t("header.validacion-codigo-postal-num"))
      .required(t("header.campo-obligatorio")),
    correoElectronico: yup
      .string()
      .email(t("header.formato"))
      .required(t("header.campo-obligatorio")),
    confirmarcorreo: yup
      .string()
      .oneOf([yup.ref("correoElectronico")], t("header.correos-no-coinciden")),
    acompanantes: yup.array().of(
      yup.object().shape({
        nombre_acompanante: yup
          .string()
          .matches(
            /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
            t("header.validacion-solo-letras")
          )
          .min(3, t("header.validacion-letras"))
          .required(t("header.campo-obligatorio")),
        apellido_acompanante: yup
          .string()
          .matches(
            /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
            t("header.validacion-solo-letras")
          )
          .min(3, t("header.validacion-letras"))
          .required(t("header.campo-obligatorio")),
        telefono_acompanante: yup
          .string()
          .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
          .min(9, t("header.validacion-telefono-num"))
          .max(9, t("header.validacion-telefono-num"))
          .required(t("header.campo-obligatorio")),
        postalCode_acompanante: yup
          .string()
          .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
          .min(5, t("header.validacion-codigo-postal-num"))
          .max(5, t("header.validacion-codigo-postal-num"))
          .required(t("header.campo-obligatorio")),
        email_acompanante: yup
          .string()
          .email(t("header.formato"))
          .required(t("header.campo-obligatorio")),
      })
    ),
    condiciones: yup
      .bool()
      .required()
      .oneOf([true], t("header.aceptar-condiciones")),
    num_entradas: yup.number(),
    seats: yup
      .array()
      .of(yup.string())
      .min(1, "Tienes que seleccionar tus Asientos"),
  });

  const schema_iralabarri = yup.object().shape({
    nombre: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
        t("header.validacion-solo-letras")
      )
      .min(3, t("header.validacion-letras"))
      .required(t("header.campo-obligatorio")),
    apellidos: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
        t("header.validacion-solo-letras")
      )
      .min(3, t("header.validacion-letras"))
      .required(t("header.campo-obligatorio")),
    telefono: yup
      .string()
      .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
      .min(9, t("header.validacion-telefono-num"))
      .max(9, t("header.validacion-telefono-num"))
      .required(t("header.campo-obligatorio")),
    codigoPostal: yup
      .string()
      .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
      .min(5, t("header.validacion-codigo-postal-num"))
      .max(5, t("header.validacion-codigo-postal-num"))
      .required(t("header.campo-obligatorio")),
    correoElectronico: yup
      .string()
      .email(t("header.formato"))
      .required(t("header.campo-obligatorio")),
    confirmarcorreo: yup
      .string()
      .oneOf([yup.ref("correoElectronico")], t("header.correos-no-coinciden")),
    acompanantes: yup.array().of(
      yup.object().shape({
        nombre_acompanante: yup
          .string()
          .matches(
            /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
            t("header.validacion-solo-letras")
          )
          .min(3, t("header.validacion-letras"))
          .required(t("header.campo-obligatorio")),
        apellido_acompanante: yup
          .string()
          .matches(
            /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
            t("header.validacion-solo-letras")
          )
          .min(3, t("header.validacion-letras"))
          .required(t("header.campo-obligatorio")),
        telefono_acompanante: yup
          .string()
          .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
          .min(9, t("header.validacion-telefono-num"))
          .max(9, t("header.validacion-telefono-num")),
        postalCode_acompanante: yup
          .string()
          .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
          .min(5, t("header.validacion-codigo-postal-num"))
          .max(5, t("header.validacion-codigo-postal-num")),
        email_acompanante: yup.string().email(t("header.formato")),
      })
    ),
    condiciones: yup
      .bool()
      .required()
      .oneOf([true], t("header.aceptar-condiciones")),
    num_entradas: yup.number(),
    //seats: yup.array().of(yup.string())
  });

  const schema_gymkana = yup.object().shape({
    nombre: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
        t("header.validacion-solo-letras")
      )
      .min(3, t("header.validacion-letras"))
      .required(t("header.campo-obligatorio")),
    apellidos: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
        t("header.validacion-solo-letras")
      )
      .min(3, t("header.validacion-letras"))
      .required(t("header.campo-obligatorio")),
    telefono: yup
      .string()
      .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
      .min(9, t("header.validacion-telefono-num"))
      .max(9, t("header.validacion-telefono-num"))
      .required(t("header.campo-obligatorio")),
    codigoPostal: yup
      .string()
      .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
      .min(5, t("header.validacion-codigo-postal-num"))
      .max(5, t("header.validacion-codigo-postal-num"))
      .required(t("header.campo-obligatorio")),
    correoElectronico: yup
      .string()
      .email(t("header.formato"))
      .required(t("header.campo-obligatorio")),
    confirmarcorreo: yup
      .string()
      .oneOf([yup.ref("correoElectronico")], t("header.correos-no-coinciden")),
    acompanantes: yup.array().of(
      yup.object().shape({
        nombre_acompanante: yup
          .string()
          .matches(
            /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
            t("header.validacion-solo-letras")
          )
          .min(3, t("header.validacion-letras"))
          .required(t("header.campo-obligatorio")),
        apellido_acompanante: yup
          .string()
          .matches(
            /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/,
            t("header.validacion-solo-letras")
          )
          .min(3, t("header.validacion-letras"))
          .required(t("header.campo-obligatorio")),
        telefono_acompanante: yup
          .string()
          .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
          .min(9, t("header.validacion-telefono-num"))
          .max(9, t("header.validacion-telefono-num")),
        postalCode_acompanante: yup
          .string()
          .matches(/^[0-9]+$/, t("header.validacion-solo-digitos"))
          .min(5, t("header.validacion-codigo-postal-num"))
          .max(5, t("header.validacion-codigo-postal-num")),
        email_acompanante: yup.string().email(t("header.formato")),
      })
    ),
    condiciones: yup
      .bool()
      .required()
      .oneOf([true], t("header.aceptar-condiciones")),
    num_entradas: yup.number(),
    direccion: yup
      .string()
      .min(3, t("header.validacion-letras"))
      .required(t("header.campo-obligatorio")),
    ciudad: yup
      .string()
      .min(3, t("header.validacion-letras"))
      .required(t("header.campo-obligatorio")),
    //seats: yup.array().of(yup.string())
  });

  const evento2Data = {
    sideBar:
      "https://anima-uploads.s3.amazonaws.com/projects/608138be200c27f99805dc8c/releases/60828d4c841fac091e8381dd/img/rectangle-167-1@1x.png",
    rectangle168:
      "https://anima-uploads.s3.amazonaws.com/projects/608138be200c27f99805dc8c/releases/60828d4c841fac091e8381dd/img/rectangle-168-1@1x.png",
    overlapGroup3:
      "https://anima-uploads.s3.amazonaws.com/projects/608138be200c27f99805dc8c/releases/60828d4c841fac091e8381dd/img/rectangle-166-1@1x.png",
  };

  return (
    <div className="pago">
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            console.log(values);
            setloading(true);
            handleNext();

            let companion = [{}];
            // eslint-disable-next-line array-callback-return
            values.acompanantes.map((a) => {
              companion.push({
                names: a.nombre_acompanante,
                surnames: a.apellido_acompanante,
                email: a.email_acompanante,
                postalCode: a.postalCode_acompanante,
                phone: a.telefono_acompanante,
              });
            });

            let info_comprador = {
              names: values.nombre,
              surnames: values.apellidos,
              phone: values.telefono,
              postalCode: values.codigoPostal,
              address: values.direccion,
              city: values.ciudad,
              password: "ontourup",
            };
            console.log(info_comprador);
            let ticketsList = [
              {
                email: values.correoElectronico,
                ticket: sesion_seleccionada._id,
                companion: companion.length === 1 ? null : companion,
                book: {
                  eventKey: evento.eventKey,
                  seats: values.seats,
                  hold: values.holdToken,
                },
              },
            ];

            //console.log(ticketsList);
            await hacerReserva(
              values.correoElectronico,
              { ticketsList },
              info_comprador,
              setEntradas,
              setloading,
              setmensajeError,
              setActiveStep,
              values.num_entradas,
              t,
              sesiones
            );

            setNumEntradas(values.num_entradas);
          }}
          initialValues={{
            nombre: "",
            apellidos: "",
            telefono: "",
            codigoPostal: "",
            correoElectronico: "",
            confirmarcorreo: "",
            ciudad: "",
            direccion: "",
            acompanantes: [],
            condiciones: false,
            num_entradas: "0",
            seats: [],
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Container style={{ marginLeft: "0px", padding: "0px" }}>
                  {/**fila 1 */}
                  <Row style={{ width: "100%", margin: "0" }}>
                    <div className="overlap-group1Res">
                      <Col style={{ width: "100%", padding: "0px" }}>
                        <Row
                          style={{
                            width: "100%",
                            margin: "0",
                            justifyContent: "center",
                            
                          }}
                        >
                          {sesiones === true ? (
                            <Stepper
                              style={{
                                padding: "0",
                                marginTop: "40px",
                                backgroundColor: "black",
                              }}
                              className="stepper"
                              activeStep={activeStep}
                              alternativeLabel
                            >
                              {steps_sesiones.map((label) => (
                                <Step key={label}>
                                  <StepLabel>
                                    <div style={{ color: "white" }}>
                                      {label}
                                    </div>
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                          ) : (
                            <Stepper
                              className="stepper"
                              activeStep={activeStep}
                              alternativeLabel
                              style={{backgroundColor:"black"}}
                            >
                              {steps.map((label) => (
                                <Step key={label}>
                                  <StepLabel>
                                    <div style={{ color: "white" }}>
                                      {label}
                                    </div>
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                          )}
                        </Row>

                        {sesiones === true ? (
                          <Container style={{ margin: "0" }}>
                            {activeStep === 0 ? (
                              ticket.map((element, key) => {
                                var disponibles =
                                  parseInt(element.amount) -
                                  parseInt(element.sellTickets);
                                element.hora_fmt = getHoraFmt(
                                  element.startDate
                                );

                                if (
                                  new Date().getTime() >=
                                  new Date(element.endDate).getTime()
                                ) {
                                  return "";
                                } else {
                                  return (
                                    <Container
                                      key={key}
                                      className="container-info"
                                    >
                                      <Col
                                        sm={true}
                                        md={8}
                                        style={{ padding: "0" }}
                                      >
                                        <Row
                                          style={{
                                            margin: "20px",
                                            height: "20px",
                                          }}
                                        >
                                          <h5 className="entrada-general">
                                            {element.name}
                                          </h5>
                                        </Row>
                                        <Row
                                          style={{
                                            margin: "20px",
                                            height: "20px",
                                          }}
                                        >
                                          <h5 className="Horaentrada-general">
                                            {element.hora_fmt}
                                          </h5>
                                        </Row>
                                        <Row
                                          style={{
                                            margin: "20px",
                                            height: "20px",
                                          }}
                                        >
                                          <h6 className="place montserrat-normal-thunder-12px">
                                            {element.netPrice === 0
                                              ? t("header.gratis")
                                              : element.netPrice}
                                          </h6>
                                        </Row>

                                        <Row
                                          style={{
                                            margin: "20px",
                                            height: "20px",
                                          }}
                                        >
                                          {evento.tags.includes(
                                            "Iralabarri bisitak"
                                          ) ||
                                          evento.tags.includes(
                                            "Iralabarri familian"
                                          )
                                            ? t("header.Plazas_disponobles") +
                                              disponibles
                                            : ""}
                                          {/*cambiar disponible por element.availability */}
                                        </Row>
                                      </Col>
                                      <Col
                                        sm={true}
                                        md={true}
                                        style={{
                                          justifyContent: "center",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {disponibles === 0 ||
                                        element.venta_activa === false ? (
                                          <Button
                                            disabled
                                            variant="flat"
                                            type="submit"
                                            onClick={() => {
                                              let j = [];
                                              if (
                                                disponibles >
                                                ticket[0].amountPerPerson
                                              ) {
                                                for (
                                                  let i = 0;
                                                  i <=
                                                  ticket[0].amountPerPerson;
                                                  i++
                                                ) {
                                                  j.push(
                                                    <option key={i} value={i}>
                                                      {i}
                                                    </option>
                                                  );
                                                }
                                              } else {
                                                for (
                                                  let i = 0;
                                                  i <= disponibles;
                                                  i++
                                                ) {
                                                  j.push(
                                                    <option key={i} value={i}>
                                                      {i}
                                                    </option>
                                                  );
                                                }
                                              }

                                              if (
                                                element._id ===
                                                  "6139158d60f89d0009360b76" ||
                                                element._id ===
                                                  "613a3c82128ed9000a5e63f8"
                                              ) {
                                                j.length = 0;
                                                j.push(
                                                  <option key={0} value={0}>
                                                    0
                                                  </option>
                                                );
                                                j.push(
                                                  <option key={2} value={2}>
                                                    2
                                                  </option>
                                                );
                                              }

                                              //console.log(j);
                                              setcantidadEntradas(j);
                                              setSesionSeleccionada(element);
                                              //console.log(element._id);
                                              handleNext();
                                            }}
                                            style={{
                                              borderRadius: "10px",
                                              backgroundColor: "gray",
                                              marginBottom: "20px",
                                              marginTop: "20px",
                                              color: "white",
                                            }}
                                          >
                                            {t("header.sin_Plazas")}
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="flat"
                                            type="submit"
                                            onClick={() => {
                                              let j = [];
                                              if (
                                                disponibles >
                                                ticket[0].amountPerPerson
                                              ) {
                                                for (
                                                  let i = 0;
                                                  i <=
                                                  ticket[0].amountPerPerson;
                                                  i++
                                                ) {
                                                  j.push(
                                                    <option key={i} value={i}>
                                                      {i}
                                                    </option>
                                                  );
                                                }
                                              } else {
                                                for (
                                                  let i = 0;
                                                  i <= disponibles;
                                                  i++
                                                ) {
                                                  j.push(
                                                    <option key={i} value={i}>
                                                      {i}
                                                    </option>
                                                  );
                                                }
                                              }
                                              if (
                                                element._id ===
                                                  "6139158d60f89d0009360b76" ||
                                                element._id ===
                                                  "613a3c82128ed9000a5e63f8"
                                              ) {
                                                j.length = 0;
                                                j.push(
                                                  <option key={0} value={0}>
                                                    0
                                                  </option>
                                                );
                                                j.push(
                                                  <option key={2} value={2}>
                                                    2
                                                  </option>
                                                );
                                              }
                                              setcantidadEntradas(j);
                                              setSesionSeleccionada(element);
                                              //console.log(element._id);
                                              handleNext();
                                            }}
                                            style={{
                                              borderRadius: "10px",
                                              backgroundColor: "red",
                                              marginBottom: "20px",
                                              marginTop: "20px",
                                              color: "white",
                                            }}
                                          >
                                            {t("header.Seleccionar-Sesion")}
                                          </Button>
                                        )}
                                      </Col>
                                    </Container> //Fin ventana sesiones multiples
                                  );
                                }
                              })
                            ) : (
                              <div>
                                {activeStep === 1 ? (
                                  <div className="datos-persona">
                                    <Row
                                      style={{
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        onClick={handlePrev}
                                        variant="link"
                                      >
                                        {t("header.Volver-seleccion-entradas")}
                                      </Button>
                                    </Row>
                                    <Row className="container-info2">
                                      <Col
                                        sm={true}
                                        md={true}
                                        style={{ padding: "0" }}
                                      >
                                        <Row
                                          style={{
                                            margin: "20px",
                                            height: "20px",
                                          }}
                                        >
                                          <h5 className="entrada-general">
                                            {sesion_seleccionada.name}
                                          </h5>
                                        </Row>
                                        <Row
                                          style={{
                                            margin: "20px",
                                            height: "20px",
                                          }}
                                        >
                                          <h5 className="Horaentrada-general">
                                            {sesion_seleccionada.hora_fmt}
                                          </h5>
                                        </Row>
                                        <Row
                                          style={{
                                            margin: "20px",
                                            height: "20px",
                                          }}
                                        >
                                          <h6 className="place montserrat-normal-thunder-12px">
                                            {sesion_seleccionada.netPrice === 0
                                              ? t("header.gratis")
                                              : sesion_seleccionada.netPrice}
                                          </h6>
                                        </Row>
                                      </Col>
                                      <Col
                                        style={{
                                          justifyContent: "center",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Row>
                                          {sesion_seleccionada.venta_activa ? (
                                            <div>
                                              <Form.Control
                                                name="num_entradas"
                                                as="select"
                                                className="overlap-group-2"
                                                onChange={(e) => {
                                                  values.acompanantes.length = 0;
                                                  if (e.target.value > 1) {
                                                    for (
                                                      let i = 0;
                                                      i < e.target.value - 1;
                                                      i++
                                                    ) {
                                                      values.acompanantes.push({
                                                        nombre_acompanante: "",
                                                        apellido_acompanante:
                                                          "",
                                                        telefono_acompanante:
                                                          "",
                                                        email_acompanante: "",
                                                        postalCode_acompanante:
                                                          "",
                                                      });
                                                    }
                                                  }
                                                  handleChange(e);
                                                }}
                                                id="selectorEntradas"
                                                value={values.num_entradas}
                                              >
                                                {cantidad_entradas}
                                              </Form.Control>
                                            </div>
                                          ) : (
                                            <div></div>
                                          )}
                                        </Row>
                                      </Col>
                                    </Row>
                                    {values.num_entradas > 0 ? (
                                      <div>
                                        <Form.Row style={{ width: "100%" }}>
                                          <Form.Group>
                                            <div className="persona-de-contacto montserrat-medium-thunder-18px">
                                              {t("header.Persona-contacto")}
                                            </div>
                                          </Form.Group>
                                        </Form.Row>
                                        <Form.Row style={{ width: "100%" }}>
                                          <Form.Group
                                            as={Col}
                                            controlId="formNombre"
                                          >
                                            <div className="nombre-1 montserrat-normal-thunder-15px">
                                              {t("header.nombre")}
                                            </div>
                                            <Form.Control
                                              type="text"
                                              name="nombre"
                                              value={values.nombre}
                                              onChange={handleChange}
                                              isInvalid={errors.nombre}
                                              placeholder={t(
                                                "header.nombre-tooltip"
                                              )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.nombre}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group
                                            as={Col}
                                            controlId="formApellidos"
                                          >
                                            <div className="nombre-1 montserrat-normal-thunder-15px">
                                              {t("header.apellido")}
                                            </div>
                                            <Form.Control
                                              type="text"
                                              name="apellidos"
                                              value={values.apellidos}
                                              onChange={handleChange}
                                              isInvalid={errors.apellidos}
                                              placeholder={t(
                                                "header.apellido-tooltip"
                                              )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.apellidos}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                        </Form.Row>
                                        <Form.Row style={{ width: "100%" }}>
                                          <Form.Group
                                            as={Col}
                                            controlId="formTelefono"
                                          >
                                            <div className="nombre-1 montserrat-normal-thunder-15px">
                                              {t("header.telefono")}
                                            </div>
                                            <Form.Control
                                              type="text"
                                              name="telefono"
                                              value={values.telefono}
                                              onChange={handleChange}
                                              isInvalid={errors.telefono}
                                              placeholder={t(
                                                "header.telefono-tooltip"
                                              )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.telefono}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group
                                            as={Col}
                                            controlId="formCodigoPostal"
                                          >
                                            <div className="nombre-1 montserrat-normal-thunder-15px">
                                              {t("header.codigo-postal")}
                                            </div>
                                            <Form.Control
                                              type="text"
                                              name="codigoPostal"
                                              value={values.codigoPostal}
                                              onChange={handleChange}
                                              isInvalid={errors.codigoPostal}
                                              placeholder={t(
                                                "header.codigo-postal-tooltip"
                                              )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.codigoPostal}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                        </Form.Row>
                                        <Form.Row style={{ width: "100%" }}>
                                          <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="formCorreo"
                                          >
                                            <div className="correo-electronico montserrat-normal-thunder-15px">
                                              {t("header.email")}
                                            </div>
                                            <Form.Control
                                              type="email"
                                              name="correoElectronico"
                                              value={values.correoElectronico}
                                              onChange={handleChange}
                                              isInvalid={
                                                errors.correoElectronico
                                              }
                                              placeholder={t(
                                                "header.email-tooltip"
                                              )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.correoElectronico}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="formConfirmarCorreo"
                                          >
                                            <div className="correo-electronico montserrat-normal-thunder-15px">
                                              {t("header.confirmar-email")}
                                            </div>
                                            <Form.Control
                                              type="email"
                                              name="confirmarcorreo"
                                              value={values.confirmarcorreo}
                                              onChange={handleChange}
                                              isInvalid={errors.confirmarcorreo}
                                              placeholder={t(
                                                "header.confirmar-email-tooltip"
                                              )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.confirmarcorreo}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                        </Form.Row>
                                        {evento.tags.includes(
                                          "Ginkana kultural"
                                        ) ? (
                                          <>
                                            <Form.Row style={{ width: "100%" }}>
                                              <Form.Group
                                                as={Col}
                                                md="6"
                                                controlId="formCorreo"
                                              >
                                                <div className="correo-electronico montserrat-normal-thunder-15px">
                                                  {t("header.direccion")}
                                                </div>
                                                <Form.Control
                                                  type="text"
                                                  name="direccion"
                                                  value={values.direccion}
                                                  onChange={handleChange}
                                                  isInvalid={errors.direccion}
                                                  placeholder={t(
                                                    "header.direccion-tooltip"
                                                  )}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  {errors.direccion}
                                                </Form.Control.Feedback>
                                              </Form.Group>

                                              <Form.Group
                                                as={Col}
                                                md="6"
                                                controlId="formConfirmarCorreo"
                                              >
                                                <div className="correo-electronico montserrat-normal-thunder-15px">
                                                  {t("header.ciudad")}
                                                </div>
                                                <Form.Control
                                                  type="text"
                                                  name="ciudad"
                                                  value={values.ciudad}
                                                  onChange={handleChange}
                                                  isInvalid={errors.ciudad}
                                                  placeholder={t(
                                                    "header.ciudad-tooltip"
                                                  )}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  {errors.ciudad}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                            </Form.Row>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    ) : (
                                      <div style={{ marginTop: "40px" }}>
                                        <h4 className="texto-entrada">
                                          {sesion_seleccionada.mensajeventa}
                                        </h4>
                                        <h5 className="texto-Error">
                                          {mensajeError}
                                        </h5>
                                      </div>
                                    )}
                                    {/* fin numentras>0 */}
                                    {values.num_entradas > 1 ? (
                                      <FieldArray name="acompanantes">
                                        {() => (
                                          <div>
                                            {values.acompanantes.map((a, i) => {
                                              const nombre = `acompanantes[${i}].nombre_acompanante`;
                                              const apellido = `acompanantes[${i}].apellido_acompanante`;
                                              const postalCode = `acompanantes[${i}].postalCode_acompanante`;
                                              const telefono = `acompanantes[${i}].telefono_acompanante`;
                                              const email = `acompanantes[${i}].email_acompanante`;
                                              return (
                                                <Container key={i}>
                                                  <Form.Row>
                                                    <Form.Group>
                                                      <div className="datos-acompaantes montserrat-medium-thunder-18px">
                                                        {t(
                                                          "header.datos-acompanante"
                                                        )}{" "}
                                                        {i + 1}
                                                      </div>
                                                    </Form.Group>
                                                  </Form.Row>

                                                  <Form.Row>
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="formNombreAcomp"
                                                    >
                                                      <div className="nombre-1 montserrat-normal-thunder-15px">
                                                        {t("header.nombre")}
                                                      </div>
                                                      <Form.Control
                                                        type="text"
                                                        name={nombre}
                                                        value={a.nombre}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                          errors.acompanantes ===
                                                            undefined ||
                                                          errors.acompanantes[
                                                            i
                                                          ] === undefined
                                                            ? ""
                                                            : errors
                                                                .acompanantes[i]
                                                                .nombre_acompanante
                                                        }
                                                        placeholder={t(
                                                          "header.nombre-tooltip"
                                                        )}
                                                      />
                                                      <Form.Control.Feedback type="invalid">
                                                        {errors.acompanantes ===
                                                          undefined ||
                                                        errors.acompanantes[
                                                          i
                                                        ] === undefined
                                                          ? ""
                                                          : errors.acompanantes[
                                                              i
                                                            ]
                                                              .nombre_acompanante}
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="formApellidosAcomp"
                                                    >
                                                      <div className="nombre-1 montserrat-normal-thunder-15px">
                                                        {t("header.apellido")}
                                                      </div>
                                                      <Form.Control
                                                        type="text"
                                                        name={apellido}
                                                        value={a.apellido}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                          errors.acompanantes ===
                                                            undefined ||
                                                          errors.acompanantes[
                                                            i
                                                          ] === undefined
                                                            ? ""
                                                            : errors
                                                                .acompanantes[i]
                                                                .apellido_acompanante
                                                        }
                                                        placeholder={t(
                                                          "header.apellido-tooltip"
                                                        )}
                                                      />
                                                      <Form.Control.Feedback type="invalid">
                                                        {errors.acompanantes ===
                                                          undefined ||
                                                        errors.acompanantes[
                                                          i
                                                        ] === undefined
                                                          ? ""
                                                          : errors.acompanantes[
                                                              i
                                                            ]
                                                              .apellido_acompanante}
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="formTelefonoAcomp"
                                                    >
                                                      <div className="nombre-1 montserrat-normal-thunder-15px">
                                                        {t("header.telefono")}
                                                      </div>
                                                      <Form.Control
                                                        type="text"
                                                        name={telefono}
                                                        value={a.telefono}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                          errors.acompanantes ===
                                                            undefined ||
                                                          errors.acompanantes[
                                                            i
                                                          ] === undefined
                                                            ? ""
                                                            : errors
                                                                .acompanantes[i]
                                                                .telefono_acompanante
                                                        }
                                                        placeholder={t(
                                                          "header.telefono-tooltip"
                                                        )}
                                                      />
                                                      <Form.Control.Feedback type="invalid">
                                                        {errors.acompanantes ===
                                                          undefined ||
                                                        errors.acompanantes[
                                                          i
                                                        ] === undefined
                                                          ? ""
                                                          : errors.acompanantes[
                                                              i
                                                            ]
                                                              .telefono_acompanante}
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  </Form.Row>

                                                  <Form.Row>
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="formEmailAcomp"
                                                    >
                                                      <div className="nombre-1 montserrat-normal-thunder-15px">
                                                        {t("header.email")}
                                                      </div>
                                                      <Form.Control
                                                        type="email"
                                                        name={email}
                                                        value={a.email}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                          errors.acompanantes ===
                                                            undefined ||
                                                          errors.acompanantes[
                                                            i
                                                          ] === undefined
                                                            ? ""
                                                            : errors
                                                                .acompanantes[i]
                                                                .email_acompanante
                                                        }
                                                        placeholder={t(
                                                          "header.email-tooltip"
                                                        )}
                                                      />
                                                      <Form.Control.Feedback type="invalid">
                                                        {errors.acompanantes ===
                                                          undefined ||
                                                        errors.acompanantes[
                                                          i
                                                        ] === undefined
                                                          ? ""
                                                          : errors.acompanantes[
                                                              i
                                                            ].email_acompanante}
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group
                                                      as={Col}
                                                      controlId="formCodigoPostalAcomp"
                                                    >
                                                      <div className="nombre-1 montserrat-normal-thunder-15px">
                                                        {t(
                                                          "header.codigo-postal"
                                                        )}
                                                      </div>
                                                      <Form.Control
                                                        type="text"
                                                        name={postalCode}
                                                        value={a.postalCode}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                          errors.acompanantes ===
                                                            undefined ||
                                                          errors.acompanantes[
                                                            i
                                                          ] === undefined
                                                            ? ""
                                                            : errors
                                                                .acompanantes[i]
                                                                .postalCode_acompanante
                                                        }
                                                        placeholder={t(
                                                          "header.codigo-postal-tooltip"
                                                        )}
                                                      />
                                                      <Form.Control.Feedback type="invalid">
                                                        {errors.acompanantes ===
                                                          undefined ||
                                                        errors.acompanantes[
                                                          i
                                                        ] === undefined
                                                          ? ""
                                                          : errors.acompanantes[
                                                              i
                                                            ]
                                                              .postalCode_acompanante}
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  </Form.Row>
                                                </Container>
                                              );
                                            })}
                                          </div>
                                        )}
                                      </FieldArray>
                                    ) : (
                                      <div></div>
                                    )}{" "}
                                  </div>
                                ) : (
                                  <Container>
                                    {loading ? (
                                      <Spinner animation="border" />
                                    ) : (
                                      <Container>
                                        <div className="text-enhorabuena">
                                          <h4>
                                            {t("header.Enhorabuena-mensaje")}
                                          </h4>
                                        </div>
                                        <p
                                          className="span1"
                                          style={{ margin: "25px" }}
                                        >
                                          {t("header.entradas-correo")}{" "}
                                          {entradas.email}
                                        </p>
                                        <Row className="info-evento">
                                          <Col>
                                            <h5 className="text-2">
                                              {t("header.info-evento")}
                                            </h5>

                                            <p className="text-2">
                                              {evento.name}
                                            </p>
                                            <p style={{color:"white"}} className="date montserrat-light-thunder-18px">
                                              {t("header.fecha-hora")}{" "}
                                              {sesion_seleccionada.fecha_fmt +
                                                " " +
                                                sesion_seleccionada.hora_fmt}
                                            </p>
                                            <p style={{color:"white"}} className="date montserrat-light-thunder-18px">
                                              {t("header.lugar")}{" "}
                                              {evento.ubication.location}
                                            </p>
                                          </Col>

                                          <Col>
                                            <h5 className="text-2">
                                              {/* {t("header.informacion-entrada")} */}
                                            </h5>
                                            <p>
                                              {/*  {t("header.numero-referencia")} {entradas.ticket} */}
                                            </p>
                                          </Col>
                                        </Row>

                                        <Row className="entrada">
                                          {entradas.data.map((data, key) => {
                                            return (
                                              <Col
                                                key={key}
                                                className="overlap-group-entrada"
                                              >
                                                <Row className="rectangle-1620">
                                                  <h6 className="entrada-1-de-2">
                                                    {t("header.entrada")}{" "}
                                                    {key + 1} {t("header.de")}{" "}
                                                    {entradas.data.length}
                                                  </h6>
                                                </Row>
                                                <Row
                                                  style={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      marginLeft: "3px",
                                                    }}
                                                  >
                                                    {data.seat !== null ? (
                                                      <Col>
                                                        <Row>
                                                          <h6
                                                            style={{
                                                              fontSize: "25px",
                                                            }}
                                                          >
                                                            Fila:{" "}
                                                            {
                                                              data.seat.split(
                                                                "-"
                                                              )[0]
                                                            }
                                                            {"   "}
                                                          </h6>
                                                        </Row>
                                                        <Row>
                                                          <h6
                                                            style={{
                                                              fontSize: "25px",
                                                            }}
                                                          >
                                                            Asiento:{" "}
                                                            {
                                                              data.seat.split(
                                                                "-"
                                                              )[1]
                                                            }
                                                          </h6>
                                                        </Row>
                                                      </Col>
                                                    ) : (
                                                      <div></div>
                                                    )}
                                                  </div>
                                                </Row>
                                                <Row className="rectangle-1619">
                                                  <QRCode
                                                    id="qrCodeEl"
                                                    className="image-4"
                                                    value={data.qr}
                                                    includeMargin={true}
                                                    level="H"
                                                    size={200}
                                                  />
                                                </Row>
                                                <Row
                                                  style={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <p
                                                    style={{
                                                      justifyContent: "center",
                                                      textAlign: "center",
                                                      marginTop: "20px",
                                                    }}
                                                  >
                                                    {t(
                                                      "header.descargar-entradas"
                                                    )}{" "}
                                                    <br />
                                                    <Button
                                                      className="buttonnQrDowloand"
                                                      variant="flat"
                                                      onClick={downloadQRCode}
                                                      style={{
                                                        backgroundImage: `url(${evento2Data.overlapGroup3})`,
                                                        color: "white",
                                                      }}
                                                    >
                                                      <div className="siguiente montserrat-normal-white-18px">
                                                        {t("header.descargar")}
                                                      </div>
                                                    </Button>
                                                  </p>
                                                </Row>
                                              </Col>
                                            );
                                          })}
                                        </Row>
                                      </Container>
                                    )}
                                  </Container>
                                )}
                              </div>
                            )}
                          </Container>
                        ) : (
                          <div>
                            {activeStep === 0 ? (
                              <div className="datos-persona">
                                <Row className="container-info2">
                                  <Col sm={1} md={8} style={{ padding: "0" }}>
                                    <Row
                                      style={{ height: "40px", margin: "20px" }}
                                    >
                                      <h5 className="entrada-general">
                                        {sesion_seleccionada.name}
                                      </h5>
                                    </Row>
                                    <Row
                                      style={{ height: "20px", margin: "20px" }}
                                    >
                                      <h5 className="Horaentrada-general">
                                        {sesion_seleccionada.hora_fmt}
                                      </h5>
                                    </Row>
                                    <Row
                                      style={{ height: "20px", margin: "20px" }}
                                    >
                                      <h6 className="place montserrat-normal-thunder-12px">
                                        {sesion_seleccionada.netPrice === 0
                                          ? t("header.gratis")
                                          : sesion_seleccionada.netPrice}
                                      </h6>
                                    </Row>
                                  </Col>

                                  <Col
                                    sm={1}
                                    md={4}
                                    style={{
                                      justifyContent: "center",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Row
                                      style={{
                                        justifyContent: "center",
                                        display: "flex",
                                        height: "80px",
                                        alignItems: "center",
                                      }}
                                    >
                                      {sesion_seleccionada.venta_activa ? (
                                        <div className="dropdown-entradas">
                                          <Form.Control
                                            name="num_entradas"
                                            as="select"
                                            className="overlap-group-2"
                                            onChange={(e) => {
                                              values.acompanantes.length = 0;
                                              if (e.target.value > 1) {
                                                for (
                                                  let i = 0;
                                                  i < e.target.value - 1;
                                                  i++
                                                ) {
                                                  values.acompanantes.push({
                                                    nombre_acompanante: "",
                                                    apellido_acompanante: "",
                                                    telefono_acompanante: "",
                                                    email_acompanante: "",
                                                    postalCode_acompanante: "",
                                                  });
                                                }
                                              }
                                              handleChange(e);
                                              /* numero de personas */

                                              if (
                                                parseInt(e.target.value) === 1
                                              ) {
                                                setCategorias(["Individual"]);
                                              }

                                              if (
                                                parseInt(e.target.value) === 2
                                              ) {
                                                setCategorias(["Doble"]);
                                              }

                                              //console.log(errors);
                                            }}
                                            id="selectorEntradas"
                                            value={values.num_entradas}
                                          >
                                            {cantidad_entradas}
                                          </Form.Control>
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </Row>
                                  </Col>
                                </Row>
                                {values.num_entradas > 0 ? (
                                  <div style={{ margin: "20px" }}>
                                    {/* agregar en la linea 827 */}
                                    {evento.isChart ? (
                                      <div>
                                        <div>
                                          <Form.Group>
                                            <SeatsioSeatingChart
                                              workspaceKey={
                                                "03659072-f55b-493a-ad8a-904854238a5b"
                                              }
                                              event={evento.eventKey}
                                              onRenderStarted={(
                                                createdChart
                                              ) => {
                                                setChart(createdChart);
                                                values.seats.length = 0;
                                              }}
                                              on
                                              onObjectSelected={(selected) => {
                                                values.seats.push(
                                                  selected.label
                                                );
                                                values.holdToken =
                                                  selected.chart.holdToken;
                                                setAsientos(values.seats);
                                              }}
                                              onObjectDeselected={() => {
                                                values.seats.length = 0;
                                                setAsientos([]);
                                              }}
                                              numberOfPlacesToSelect={parseInt(
                                                values.num_entradas
                                              )}
                                              availableCategories={[
                                                "Individual",
                                              ]}
                                              language={"es"}
                                              session="start"
                                              region="eu"
                                            />
                                            <Row
                                              style={{
                                                width: "100%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: "30px",
                                              }}
                                            >
                                              <Table striped bordered hover>
                                                <thead>
                                                  <tr>
                                                    <th>#</th>
                                                    <th>Fila</th>
                                                    <th>Asiento</th>
                                                    <th>categoria</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {asientos.map((data, key) => {
                                                    let data_fmt =
                                                      data.split("-");
                                                    //console.log(asiento)
                                                    return (
                                                      <tr key={key}>
                                                        <td>{key + 1}</td>
                                                        <td>{data_fmt[0]}</td>
                                                        <td>{data_fmt[1]}</td>
                                                        <td>{categorias[0]}</td>
                                                      </tr>
                                                    );
                                                  })}
                                                </tbody>
                                              </Table>
                                              <Form.Control.Feedback type="invalid">
                                                {errors.seats}
                                              </Form.Control.Feedback>
                                            </Row>
                                          </Form.Group>
                                        </div>
                                      </div>
                                    ) : (
                                      /* eliminar remplazar por <div></div>  */
                                      <div></div>
                                    )}

                                    <Form.Row style={{ width: "100%" }}>
                                      <Form.Group>
                                        <div className="persona-de-contacto montserrat-medium-thunder-18px">
                                          {t("header.Persona-contacto")}
                                        </div>
                                      </Form.Group>
                                    </Form.Row>

                                    <Form.Row style={{ width: "100%" }}>
                                      <Form.Group
                                        as={Col}
                                        controlId="formNombre"
                                      >
                                        <div className="nombre-1 montserrat-normal-thunder-15px">
                                          {t("header.nombre")}
                                        </div>
                                        <Form.Control
                                          type="text"
                                          name="nombre"
                                          value={values.nombre}
                                          onChange={handleChange}
                                          isInvalid={errors.nombre}
                                          placeholder={t(
                                            "header.nombre-tooltip"
                                          )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.nombre}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                      <Form.Group
                                        as={Col}
                                        controlId="formApellidos"
                                      >
                                        <div className="nombre-1 montserrat-normal-thunder-15px">
                                          {t("header.apellido")}
                                        </div>
                                        <Form.Control
                                          type="text"
                                          name="apellidos"
                                          value={values.apellidos}
                                          onChange={handleChange}
                                          isInvalid={errors.apellidos}
                                          placeholder={t(
                                            "header.apellido-tooltip"
                                          )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.apellidos}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row style={{ width: "100%" }}>
                                      <Form.Group
                                        as={Col}
                                        controlId="formTelefono"
                                      >
                                        <div className="nombre-1 montserrat-normal-thunder-15px">
                                          {t("header.telefono")}
                                        </div>
                                        <Form.Control
                                          type="text"
                                          name="telefono"
                                          value={values.telefono}
                                          onChange={handleChange}
                                          isInvalid={errors.telefono}
                                          placeholder={t(
                                            "header.telefono-tooltip"
                                          )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.telefono}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                      <Form.Group
                                        as={Col}
                                        controlId="formCodigoPostal"
                                      >
                                        <div className="nombre-1 montserrat-normal-thunder-15px">
                                          {t("header.codigo-postal")}
                                        </div>
                                        <Form.Control
                                          type="text"
                                          name="codigoPostal"
                                          value={values.codigoPostal}
                                          onChange={handleChange}
                                          isInvalid={errors.codigoPostal}
                                          placeholder={t(
                                            "header.codigo-postal-tooltip"
                                          )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.codigoPostal}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row style={{ width: "100%" }}>
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        controlId="formCorreo"
                                      >
                                        <div className="correo-electronico montserrat-normal-thunder-15px">
                                          {t("header.email")}
                                        </div>
                                        <Form.Control
                                          type="email"
                                          name="correoElectronico"
                                          value={values.correoElectronico}
                                          onChange={handleChange}
                                          isInvalid={errors.correoElectronico}
                                          placeholder={t(
                                            "header.email-tooltip"
                                          )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.correoElectronico}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        controlId="formConfirmarCorreo"
                                      >
                                        <div className="correo-electronico montserrat-normal-thunder-15px">
                                          {t("header.confirmar-email")}
                                        </div>
                                        <Form.Control
                                          type="email"
                                          name="confirmarcorreo"
                                          value={values.confirmarcorreo}
                                          onChange={handleChange}
                                          isInvalid={errors.confirmarcorreo}
                                          placeholder={t(
                                            "header.confirmar-email-tooltip"
                                          )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.confirmarcorreo}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Form.Row>
                                    {evento.tags.includes(
                                      "Ginkana kultural"
                                    ) ? (
                                      <>
                                        <Form.Row style={{ width: "100%" }}>
                                          <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="formCorreo"
                                          >
                                            <div className="correo-electronico montserrat-normal-thunder-15px">
                                              {t("header.direccion")}
                                            </div>
                                            <Form.Control
                                              type="text"
                                              name="direccion"
                                              value={values.direccion}
                                              onChange={handleChange}
                                              isIn2valid={errors.direccion}
                                              placeholder={t(
                                                "header.direccion-tooltip"
                                              )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.direccion}
                                            </Form.Control.Feedback>
                                          </Form.Group>

                                          <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="formConfirmarCorreo"
                                          >
                                            <div className="correo-electronico montserrat-normal-thunder-15px">
                                              {t("header.ciudad")}
                                            </div>
                                            <Form.Control
                                              type="text"
                                              name="ciudad"
                                              value={values.ciudad}
                                              onChange={handleChange}
                                              isInvalid={errors.ciudad}
                                              placeholder={t(
                                                "header.ciudad-tooltip"
                                              )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.ciudad}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                        </Form.Row>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ) : (
                                  <div style={{ marginTop: "40px" }}>
                                    <h4 className="texto-entrada">
                                      {sesion_seleccionada.mensajeventa}
                                    </h4>
                                    <h5 className="texto-Error">
                                      {mensajeError}
                                    </h5>
                                  </div>
                                )}
                                {/* fin numentras>0 */}
                                {values.num_entradas > 1 ? (
                                  <FieldArray name="acompanantes">
                                    {() => (
                                      <div>
                                        {values.acompanantes.map((a, i) => {
                                          const nombre = `acompanantes[${i}].nombre_acompanante`;
                                          const apellido = `acompanantes[${i}].apellido_acompanante`;
                                          const postalCode = `acompanantes[${i}].postalCode_acompanante`;
                                          const telefono = `acompanantes[${i}].telefono_acompanante`;
                                          const email = `acompanantes[${i}].email_acompanante`;
                                          // console.log(i)
                                          return (
                                            <Container key={i}>
                                              <Form.Row>
                                                <Form.Group>
                                                  <div className="datos-acompaantes montserrat-medium-thunder-18px">
                                                    {t(
                                                      "header.datos-acompanante"
                                                    )}{" "}
                                                    {i + 1}
                                                  </div>
                                                </Form.Group>
                                              </Form.Row>

                                              <Form.Row>
                                                <Form.Group
                                                  as={Col}
                                                  controlId="formNombreAcomp"
                                                >
                                                  <div className="nombre-1 montserrat-normal-thunder-15px">
                                                    {t("header.nombre")}
                                                  </div>
                                                  <Form.Control
                                                    type="text"
                                                    name={nombre}
                                                    value={a.nombre}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                      errors.acompanantes ===
                                                        undefined ||
                                                      errors.acompanantes[i] ===
                                                        undefined
                                                        ? ""
                                                        : errors.acompanantes[i]
                                                            .nombre_acompanante
                                                    }
                                                    placeholder={t(
                                                      "header.nombre-tooltip"
                                                    )}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors.acompanantes ===
                                                      undefined ||
                                                    errors.acompanantes[i] ===
                                                      undefined
                                                      ? ""
                                                      : errors.acompanantes[i]
                                                          .nombre_acompanante}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group
                                                  as={Col}
                                                  controlId="formApellidosAcomp"
                                                >
                                                  <div className="nombre-1 montserrat-normal-thunder-15px">
                                                    {t("header.apellido")}
                                                  </div>
                                                  <Form.Control
                                                    type="text"
                                                    name={apellido}
                                                    value={a.apellido}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                      errors.acompanantes ===
                                                        undefined ||
                                                      errors.acompanantes[i] ===
                                                        undefined
                                                        ? ""
                                                        : errors.acompanantes[i]
                                                            .apellido_acompanante
                                                    }
                                                    placeholder={t(
                                                      "header.apellido-tooltip"
                                                    )}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors.acompanantes ===
                                                      undefined ||
                                                    errors.acompanantes[i] ===
                                                      undefined
                                                      ? ""
                                                      : errors.acompanantes[i]
                                                          .apellido_acompanante}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group
                                                  as={Col}
                                                  controlId="formTelefonoAcomp"
                                                >
                                                  <div className="nombre-1 montserrat-normal-thunder-15px">
                                                    {t("header.telefono")}
                                                  </div>
                                                  <Form.Control
                                                    type="text"
                                                    name={telefono}
                                                    value={a.telefono}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                      errors.acompanantes ===
                                                        undefined ||
                                                      errors.acompanantes[i] ===
                                                        undefined
                                                        ? ""
                                                        : errors.acompanantes[i]
                                                            .telefono_acompanante
                                                    }
                                                    placeholder={t(
                                                      "header.telefono-tooltip"
                                                    )}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors.acompanantes ===
                                                      undefined ||
                                                    errors.acompanantes[i] ===
                                                      undefined
                                                      ? ""
                                                      : errors.acompanantes[i]
                                                          .telefono_acompanante}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              </Form.Row>

                                              <Form.Row>
                                                <Form.Group
                                                  as={Col}
                                                  controlId="formEmailAcomp"
                                                >
                                                  <div className="nombre-1 montserrat-normal-thunder-15px">
                                                    {t("header.email")}
                                                  </div>
                                                  <Form.Control
                                                    type="email"
                                                    name={email}
                                                    value={a.email}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                      errors.acompanantes ===
                                                        undefined ||
                                                      errors.acompanantes[i] ===
                                                        undefined
                                                        ? ""
                                                        : errors.acompanantes[i]
                                                            .email_acompanante
                                                    }
                                                    placeholder={t(
                                                      "header.email-tooltip"
                                                    )}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors.acompanantes ===
                                                      undefined ||
                                                    errors.acompanantes[i] ===
                                                      undefined
                                                      ? ""
                                                      : errors.acompanantes[i]
                                                          .email_acompanante}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group
                                                  as={Col}
                                                  controlId="formCodigoPostalAcomp"
                                                >
                                                  <div className="nombre-1 montserrat-normal-thunder-15px">
                                                    {t("header.codigo-postal")}
                                                  </div>
                                                  <Form.Control
                                                    type="text"
                                                    name={postalCode}
                                                    value={a.postalCode}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                      errors.acompanantes ===
                                                        undefined ||
                                                      errors.acompanantes[i] ===
                                                        undefined
                                                        ? ""
                                                        : errors.acompanantes[i]
                                                            .postalCode_acompanante
                                                    }
                                                    placeholder={t(
                                                      "header.codigo-postal-tooltip"
                                                    )}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors.acompanantes ===
                                                      undefined ||
                                                    errors.acompanantes[i] ===
                                                      undefined
                                                      ? ""
                                                      : errors.acompanantes[i]
                                                          .postalCode_acompanante}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              </Form.Row>
                                            </Container>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </FieldArray>
                                ) : (
                                  <div></div>
                                )}{" "}
                                {/* fin numentras > 1 */}
                              </div> /**fin div general */
                            ) : (
                              /** */

                              <Container>
                                {loading ? (
                                  <Spinner animation="border" />
                                ) : (
                                  <Container>
                                    <div className="text-enhorabuena">
                                      <h4>{t("header.Enhorabuena-mensaje")}</h4>
                                    </div>
                                    <p
                                      className="span1"
                                      style={{ margin: "25px" }}
                                    >
                                      {t("header.entradas-correo")}{" "}
                                      {entradas.email}
                                    </p>
                                    <Row className="info-evento">
                                      <Col>
                                        <h5 className="text-2">
                                          {t("header.info-evento")}
                                        </h5>

                                        <p className="text-2">{evento.name}</p>
                                        <p style={{color:"white"}} className="date montserrat-light-thunder-18px">
                                          {t("header.fecha-hora")}{" "}
                                          {evento.fecha_fmt +
                                            " " +
                                            evento.hora_fmt}
                                        </p>
                                        <p style={{color:"white"}} className="date montserrat-light-thunder-18px">
                                          {t("header.lugar")}{" "}
                                          {evento.ubication.location}
                                        </p>
                                      </Col>

                                      <Col>
                                        <h5 className="text-2">
                                          {/* {t("header.informacion-entrada")} */}
                                        </h5>
                                        <p>
                                          {/*  {t("header.numero-referencia")} {entradas.ticket} */}
                                        </p>
                                      </Col>
                                    </Row>

                                    <Row className="entrada">
                                      {entradas.data.map((data, key) => {
                                        console.log(data);
                                        return (
                                          <Col
                                            key={key}
                                            className="overlap-group-entrada"
                                          >
                                            <Row className="rectangle-1620">
                                              <h6 className="entrada-1-de-2">
                                                {t("header.entrada")} {key + 1}{" "}
                                                {t("header.de")}{" "}
                                                {entradas.data.length}
                                              </h6>
                                            </Row>
                                            <Row
                                              style={{ marginButton: "20px" }}
                                              className="rectangle-1620"
                                            >
                                              <div
                                                style={{ marginLeft: "3px" }}
                                              >
                                                {data.seat !== null ? (
                                                  <Col>
                                                    <Row>
                                                      <h6
                                                        style={{
                                                          fontSize: "1.2rem",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        Fila:{" "}
                                                        {
                                                          data.seat.split(
                                                            "-"
                                                          )[0]
                                                        }
                                                        {"   "}
                                                      </h6>
                                                    </Row>
                                                    <Row>
                                                      <h6
                                                        style={{
                                                          fontSize: "1.2rem",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        Asiento:{" "}
                                                        {
                                                          data.seat.split(
                                                            "-"
                                                          )[1]
                                                        }
                                                      </h6>
                                                    </Row>
                                                  </Col>
                                                ) : (
                                                  <div></div>
                                                )}
                                              </div>
                                            </Row>
                                            <Row className="rectangle-1619">
                                              <QRCode
                                                id="qrCodeEl"
                                                className="image-4"
                                                value={data.qr}
                                                includeMargin={true}
                                                level="H"
                                                size={200}
                                              />
                                            </Row>
                                            <Row
                                              style={{
                                                justifyContent: "center",
                                                display: "flex",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  justifyContent: "center",
                                                  textAlign: "center",
                                                  marginTop: "20px",
                                                  color:"white"
                                                }}
                                              >
                                                {t("header.descargar-entradas")}{" "}
                                                <br />
                                                <Button
                                                  className="buttonnQrDowloand"
                                                  variant="flat"
                                                  onClick={downloadQRCode}
                                                  style={{
                                                    backgroundImage: `url(${evento2Data.overlapGroup3})`,
                                                    color: "white",
                                                  }}
                                                >
                                                  <div className="siguiente montserrat-normal-white-18px">
                                                    {t("header.descargar")}
                                                  </div>
                                                </Button>
                                              </p>
                                            </Row>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </Container>
                                )}
                              </Container>
                            )}
                          </div>
                        )}
                      </Col>
                    </div>
                  </Row>
                  {/**fila 2 */}
                  <Row style={{ width: "100%", marginLeft: "0px" }}>
                    {activeStep === 0 ? (
                      <></>
                    ) : (
                      <div
                        className="side-bar"
                        /* style={{
                          backgroundImage: `url(${evento2Data.sideBar})`,
                        }} */
                      ></div>
                    )}
                  </Row>
                  {/**fila 3 */}
                  <Row style={{ width: "100%", margin: "0" }}>
                    <div
                      className="overlap-group-4"
                      /* style={{
                        backgroundImage: `url(${evento2Data.rectangle168})`,
                      }} */
                    >
                      {sesiones === true ? (
                        <div>
                          {activeStep === 0 ? (
                            <div></div>
                          ) : (
                            <div>
                              {activeStep === 1 ? (
                                <Container className="group-5552">
                                  <Row
                                    style={{
                                      justifyContent: "center",
                                      marginLeft: "0px",
                                      marginRight: "0px",
                                    }}
                                  >
                                    {sesion_seleccionada.venta_activa ? (
                                      <Form.Group>
                                        <Form.Check
                                          required
                                          name="condiciones"
                                          label={textocondiciones()}
                                          onChange={handleChange}
                                          value={values.condiciones}
                                          isInvalid={!!errors.condiciones}
                                          feedback={errors.condiciones}
                                          id="validationFormik0"
                                          onClick={handleShow}
                                        />
                                        <Modal show={show} onHide={handleClose}>
                                          <Modal.Header closeButton>
                                            <Modal.Title>
                                              {t("header.condiciones")}
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            {t("header.mensaje-condicciones")}
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <Button
                                              variant="primary"
                                              onClick={handleClose}
                                            >
                                              {t("header.aceptar")}
                                            </Button>
                                          </Modal.Footer>
                                        </Modal>
                                      </Form.Group>
                                    ) : (
                                      <div></div>
                                    )}
                                  </Row>
                                  <Row
                                    style={{
                                      justifyContent: "center",
                                      height: "130px",
                                    }}
                                  >
                                    {sesion_seleccionada.venta_activa ? (
                                      <Button
                                        className="buttonnormalRes"
                                        variant="flat"
                                        type="submit"
                                        onClick={handleSubmit}
                                        style={{
                                          backgroundImage: `url(${evento2Data.overlapGroup3})`,
                                          color: "white",
                                          marginTop: "45px",
                                        }}
                                      >
                                        <div className="overlap-group3Res">
                                          <div className="siguiente montserrat-normal-white-18px">
                                            {t("header.siguiente")}
                                          </div>
                                        </div>
                                      </Button>
                                    ) : (
                                      <div></div>
                                    )}
                                  </Row>
                                </Container>
                              ) : (
                                <Row className="group-555">
                                  <Row className="texto-Confirmacion">
                                    {t("header.texto")}
                                  </Row>
                                  <Row
                                    style={{
                                      marginLeft: "0px",
                                      marginTop: "40px",
                                      justifyContent: "center",
                                      display: "flex",
                                      marginRight: "0px",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <Link to={"/"}>
                                      <div
                                        className="overlap-group1-2Res"
                                        style={{
                                          backgroundImage: `url(${evento2Data.overlapGroup3})`,
                                        }}
                                      >
                                        <div className="reservar-entradas">
                                          {t("header.volver")}
                                        </div>
                                      </div>
                                    </Link>
                                  </Row>
                                </Row>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {activeStep === 0 ? (
                            <Container className="group-5552">
                              <Row
                                style={{
                                  justifyContent: "center",
                                  marginLeft: "0px",
                                  marginRight: "0px",
                                }}
                              >
                                {sesion_seleccionada.venta_activa ? (
                                  <Form.Group>
                                    <Form.Check
                                      required
                                      name="condiciones"
                                      label={textocondiciones()}
                                      onChange={handleChange}
                                      value={values.condiciones}
                                      isInvalid={!!errors.condiciones}
                                      feedback={errors.condiciones}
                                      id="validationFormik0"
                                      onClick={handleShow}
                                    />
                                    <Modal show={show} onHide={handleClose}>
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          {t("header.condiciones")}
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        {t("header.mensaje-condicciones")}
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="primary"
                                          onClick={handleClose}
                                        >
                                          {t("header.aceptar")}
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </Form.Group>
                                ) : (
                                  <div></div>
                                )}
                              </Row>
                              <Row
                                style={{
                                  justifyContent: "center",
                                  height: "130px",
                                }}
                              >
                                {sesion_seleccionada.venta_activa ? (
                                  <Button
                                    className="buttonnormalRes"
                                    variant="flat"
                                    type="submit"
                                    onClick={handleSubmit}
                                    style={{
                                      backgroundImage: `url(${evento2Data.overlapGroup3})`,
                                      color: "white",
                                      marginTop: "45px",
                                    }}
                                  >
                                    <div className="overlap-group3Res">
                                      <div className="siguiente montserrat-normal-white-18px">
                                        {t("header.siguiente")}
                                      </div>
                                    </div>
                                  </Button>
                                ) : (
                                  <div></div>
                                )}
                              </Row>
                            </Container>
                          ) : (
                            <Row className="group-555">
                              <Row className="texto-Confirmacion">
                                {t("header.texto")}
                              </Row>
                              <Row
                                style={{
                                  marginLeft: "0px",
                                  marginTop: "40px",
                                  justifyContent: "center",
                                  display: "flex",
                                  marginRight: "0px",
                                  marginBottom: "30px",
                                }}
                              >
                                <Link to={"/"}>
                                  <div
                                    className="overlap-group1-2Res"
                                    style={{
                                      backgroundImage: `url(${evento2Data.overlapGroup3})`,
                                    }}
                                  >
                                    <div className="reservar-entradas">
                                      {t("header.volver")}
                                    </div>
                                  </div>
                                </Link>
                              </Row>
                            </Row>
                          )}
                        </div>
                      )}
                    </div>
                  </Row>
                </Container>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default Reserva;
