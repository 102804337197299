import "./SelectorIdioma.css";

import { Dropdown } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

function SelectorIdioma() {
  const [t, i18n] = useTranslation("global");

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{color: "white",fontSize:"15px"}}
    >
      {children}
      &#x25bc;
    </a>
  ));

  



  return (
    <div className="selector-idioma">
      <Dropdown className="botonIdioma" onSelect = {(eventKey) => {
        switch (eventKey) {
          case "es":
            i18n.changeLanguage("es");
            break;
        
            case "en":
              i18n.changeLanguage("en");
            break;
        
          default:
            break;
        }
      }}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-1">
          {i18n.language === "es"
            ? t("header.castellano")
            : t("header.ingles")}
        </Dropdown.Toggle>
        <Dropdown.Menu className="super-colors">
          <Dropdown.Item  eventKey="es">{t("header.castellano")}</Dropdown.Item>
          <Dropdown.Item  eventKey="en">{t("header.ingles")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
export default SelectorIdioma;
