import "../../styles/styles.css";

import { Button, Col, Container, Row, Spinner, Tab } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { getDiaEvento, getHoraFmt } from "../../utils/fechas";

import CartaEventoBono from "../CartaEvento/CartaEvento_bono";
import SelectorIdioma from "../SelectorIdioma/SelectorIdioma";
import entrances from "../../services/entrances";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CanjeBonos() {
  let location = useLocation();

  var eventos_ciclo = location.state.eventos_ciclo;
  var infoUser = location.state.info;
  //console.log(eventos_ciclo)
  const [t] = useTranslation("global");
  // eslint-disable-next-line no-unused-vars
  const [eventos_disponibles, setEventosDisponibles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bonos, setBonos] = useState([]);
  var bono3 = "614da163bfe4da000a616cf7";
  var bono5 = "614da163c9e964000a40c1d8";

  useEffect(() => {
    entrances.checkBonos([bono3, bono5], infoUser._id).then((res) => {
      let bonos_activos = [];
      res.forEach((element) => {
        if (element.number_used === null) {
          bonos_activos.push(element);
        } else {
          if (element.ticket === bono3 && element.number_used < 3) {
            bonos_activos.push(element);
          }

          if (element.ticket === bono5 && element.number_used < 5) {
            bonos_activos.push(element);
          }
        }
      });
      setBonos(bonos_activos);
    });
    eventos_disponibles.length = 0;
    //console.log(eventos_ciclo)
    eventos_ciclo.forEach((element) => {
      let isCanjeable = false
      if (element.tickets.length > 0) {
        element.tickets.forEach((element) => {
          element.hora_fmt = getHoraFmt(element.startDate);
          if(element.netPrice > 0 && element.netPrice < 15 ){
            isCanjeable = true
          }
        });
        element.dia_fmt = getDiaEvento(element.startDate);
        element.dia_fmt = getDiaEvento(element.startDate);
        let minutes;
        let hora = new Date(element.startDate).getHours();
        if (new Date(element.startDate).getMinutes() < 10) {
          minutes = "0" + new Date(element.startDate).getMinutes();
        } else {
          minutes = new Date(element.startDate).getMinutes();
        }
        element.hora_fmt = hora + ":" + minutes;

        
      }else{
        if(element._id === "614e002be3abb4000a366b80" || element._id === "614e05f881648b000adcfe38" || element._id === "614ee296c22f790009c7e5f2" || element._id === "614ee4aef190a7000aca640a" ){
          isCanjeable = false
        }else{
          isCanjeable = true
        }
        
      }

      if(isCanjeable) {
        eventos_disponibles.push(element);
      }
    });

    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Container fluid className="home">
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Tab.Container >
          <div>
            <Row style={{ height: "40px" }}>
              <Col
                style={{
                  position: "absolute",
                  right: "0%",
                  justifyContent: "flex-start",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SelectorIdioma />
              </Col>
            </Row>

            <Row>
              <div className="bannerHome" alt=""></div>
            </Row>
            <Row
              style={{
                width: "70%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "-50px",
                justifyContent: "center",
              }}
            >
              <div className="selectorHome">
                <div className="selecciona-el-cicloHome">
                  {t("header.Canjear_bonos")}
                </div>
              </div>
            </Row>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <Container style={{ marginTop: "60px" }}>
                <Row style={{ justifyContent: "center", display: "flex" }}>
               
                    <Col
                      sm={8}
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        marginBottom: "30px"
                      }}
                    >
                      {t("header.usuario")} {infoUser.email}
                    </Col>
                
                  
                    <Col
                      sm={8}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginLeft: "20px",
                        height: "fit-content"
                      }}
                    >
                      {" "}
                      {bonos.map((data, key) => {
                        let i = key + 1
                        return <Row style={{margin : "0", justifyContent: "left", display: "block", width: "100%"}} key={i}>
                          {/* <Row>Bono {key}</Row> */}
                          <Row>{data.ticket === "614da163bfe4da000a616cf7" ? "BONO BAD 3 ikuskizun / 3 espectáculos" : "BONO BAD 5 ikuskizun / 5 espectáculos"}</Row>
                          <Row>{t("header.numero_eventos_usados")} {data.number_used} / {data.ticket === "614da163bfe4da000a616cf7" ? 3 : 5}</Row>
                        </Row>
                      })}
                    </Col>
                 
                </Row>
                <Row
                  style={{
                    marginLeft: "0",
                    marginRight: "0",
                    marginTop: "60px",
                  }}
                  xs={1}
                  lg={2}
                >
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      height: "60px",
                    }}
                  >
                    <div className="selecion-mesCal">
                      <h4 className="calendario-eventos text-wrap">
                        {t("header.Eventos_disponibles")}
                      </h4>
                    </div>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="mesesCal"></div>
                  </Col>
                </Row>
                <div
                  className="row row-cols-lg-10 row-cols-1"
                  style={{ marginLeft: "0", marginBottom: "150px" }}
                >
                  <Tab.Content>
                    {bonos.length > 0 ? (
                      <Row style={{ justifyContent: "center" }}>
                        <Row
                          style={{
                            justifyContent: "center",
                            marginLeft: "0px",
                            marginRight: "0px",
                          }}
                        >
                          {eventos_disponibles.map((data, i) => {
                            return (
                              <CartaEventoBono
                                key={i}
                                evento={data}
                                bono={true}
                                user={infoUser}
                                entrance={bonos}
                              />
                            );
                          })}
                        </Row>
                      </Row>
                    ) : (

                      <div><Row
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <Col
                          xs={6}
                          md={8}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            marginTop: "30px",
                            fontSize: "22px"
                          }}
                        >
                          {t("header.sin_bonos")}
                        </Col>
                      </Row>
                      <Row xs={6}
                          md={8}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            margin: "0px",
                            marginTop:"30px"
                          }}>
                      <a style={{justifyContent: "center", display: "flex"}} href="https://eventos.ontourup.com/event/c0a78c7d-52aa-499d-951b-c18ecab3dde7/tickets">
                        <Button
                          style={{ width: "80%" }}
                          variant="primary"
                        >
                          {t("header.Compra_bonos")}
                        </Button>
                      </a>
                      </Row>
                      </div>
                    )}
                  </Tab.Content>
                </div>
              </Container>
            )}
          </div>
        </Tab.Container>
      )}
    </Container>
  );
}

export default CanjeBonos;
