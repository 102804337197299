import "../../styles/styles.css";
import "./../../utils/fechas";

import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
/* import { replace } from "formik"; */
import bannerMelodic from "../../assets/ComeBack_avatar4Junio.jpg";
import bannerSeth from "../../assets/30JUNIOSETHWALKER.png";

//import { getDiaEvento, getFechaFmt, getHoraFmt } from "./../../utils/fechas";

function CartaEvento({ evento }) {
  const [t] = useTranslation("global");
  const monthNames = [
    "ENERO",
    "FEBREO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];

  const getShortMonthName = function (date) {
    return monthNames[date.getMonth()].substring(0, 3);
  };

  useEffect(() => {
    /*  console.log(evento); */
    evento.tickets.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
    /* console.log(evento.tickets); */
  }, [evento]);
  return (
    <React.Fragment>
      <div className="carta-evento">
        <Link
          to={{
            pathname: "/evento/" + evento._id,
          }}
        >
          <div className="overlap-groupCart">
            <Row>

              {evento.name === "Melodic Rock Festival: The Comeback!" ? (
                <img className="cartaEveMelodic" src={bannerMelodic} alt="cartaMelodic"></img>
              ) : (
                <>
                  {evento.ubication.city === "Zaragoza" ? (
                    <div
                      className="cartaEveZaragosa"
                      style={{ backgroundImage: `url(${evento.banner})` }}
                    >
                    </div>
                  ) : (
                    <>
                      {evento.ubication.city === "Gasteiz" ? (
                        <div
                          className="cartaEveVitoria"
                          style={{ backgroundImage: `url(${evento.banner})` }}
                        >
                        </div>
                      ) : (
                        <>
                          {evento.name === "SETH WALKER" ? (
                            <img className="cartaEveMelodic" src={bannerSeth} alt="cartaMelodic"></img>
                          ) : (
                            <div
                              className="cartaEve"
                              style={{ backgroundImage: `url(${evento.banner})` }}
                            >
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Row>
            {evento.ubication.city === "Zaragoza" ?
              (
                <div className="fechaEveZaragoza">
                  <Row style={{ marginTop: "-14px", marginLeft: "-17px" }}>
                    <h1 style={{ fontSize: "32px" }} className="numberEve">
                      {evento.dia_fmt}
                    </h1>
                  </Row>
                  <Row
                    style={{
                      marginTop: "40px",
                      marginLeft: "-20px",
                    }}
                  >
                    <h1 style={{ fontSize: "18px" }} className="numberEve">
                      {getShortMonthName(new Date(evento.startDate))}
                    </h1>
                  </Row>
                </div>
              ) : (
                <div className="fechaEve">
                  <Row style={{ marginTop: "-14px", marginLeft: "-17px" }}>
                    <h1 style={{ fontSize: "32px" }} className="numberEve">
                      {evento.dia_fmt}
                    </h1>
                  </Row>
                  <Row
                    style={{
                      marginTop: "40px",
                      marginLeft: "-20px",
                    }}
                  >
                    <h1 style={{ fontSize: "18px" }} className="numberEve">
                      {getShortMonthName(new Date(evento.startDate))}
                    </h1>
                  </Row>
                </div>
              )}

            <Row>
              <div className="cartaEve2">
                {evento.tickets.length === 0 ||
                  evento.tickets[0].netPrice === 0 ? (
                  <div className="infoEve" style={{ backgroundColor: "white" }}>
                    <div className="nombreEvento montserrat-semi-bold-thunder-25px">
                      {evento.name}
                    </div>
                    <Col>
                      {evento.tags[0] === "Errekaldeberri bisitak" ? (
                        <div></div>
                      ) : (
                        <div>
                          {evento.tickets.length === 0 ? (
                            <div className="hora-1830Eve montserrat-normal-thunder-22px">
                              {t("header.hora")}
                              {evento.hora_fmt}
                            </div>
                          ) : (
                            <div className="hora-1830Eve montserrat-normal-thunder-22px">
                              {t("header.hora")}
                              <>
                                {evento.tickets.length < 4 ? (
                                  <>
                                    {evento.tickets[0].hora_fmt ===
                                      evento.tickets[1].hora_fmt ? (
                                      <div className="hora-1830Eve montserrat-normal-thunder-22px">
                                        {t("header.hora")}
                                        {evento.hora_fmt}
                                      </div>
                                    ) : (
                                      <>
                                        {evento.tickets.map((data, i) => {
                                          return (
                                            <div key={i}>
                                              {data.hora_fmt}{" "}
                                              {evento.tickets.length - 1 === i
                                                ? ""
                                                : " / "}
                                            </div>
                                          );
                                        })}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {evento.tickets[0].hora_fmt}
                                    {"-"}
                                    {
                                      evento.tickets[evento.tickets.length - 1]
                                        .hora_fmt
                                    }
                                  </>
                                )}
                              </>
                            </div>
                          )}
                        </div>
                      )}

                      <div className="text-2Eve montserrat-normal-thunder-22px">
                        {t("header.lugar")}
                        {evento.ubication.location}
                      </div>

                      {evento.tickets.length === 0 ||
                        evento.tickets[0].name === "Bono" ? (
                        <div className="hora-1830Eve montserrat-normal-thunder-22px"></div>
                      ) : (
                        <div className="text-2Eve montserrat-normal-thunder-22px">
                          {evento.tickets[0].netPrice === 0 ? (
                            <div>
                              {t("header.precio")} {t("header.gratis")}
                            </div>
                          ) : (
                            <div>
                              {evento.name === "Melodic Rock Festival: The Comeback!" ?
                                (
                                  <>
                                    {t("header.precio")} 36 {"€"}
                                  </>
                                ) : (
                                  <>
                                    {t("header.precio")} {evento.tickets[0].netPrice}
                                    {"€"}</>)}

                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </div>
                ) : (
                  <div
                    className="infoEve"
                    style={{ backgroundColor: "lightgoldenrodyellow" }}
                  >
                    <div className="nombreEvento montserrat-semi-bold-thunder-25px">
                      {evento.name}
                    </div>
                    <Col>
                      {evento.tags[0] === "Errekaldeberri bisitak" ? (
                        <div className="hora-1830Eve montserrat-normal-thunder-22px">
                          {t("header.todooctubre")}
                        </div>
                      ) : (
                        <div>
                          {evento.tickets.length === 0 ? (
                            <div className="hora-1830Eve montserrat-normal-thunder-22px">
                              {t("header.hora")}
                              {evento.hora_fmt}
                            </div>
                          ) : (
                            <div className="hora-1830Eve montserrat-normal-thunder-22px">
                              {t("header.hora")}
                              {evento.tickets[0].hora_fmt}
                              {evento.tickets.length > 1 ? (
                                <>
                                  {evento.tickets.length < 4 ? (
                                    <>
                                      {evento.tickets[0].hora_fmt ===
                                        evento.tickets[1].hora_fmt ? (
                                        <></>
                                      ) : (
                                        <>/ {evento.tickets[1].hora_fmt}</>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              {/*  {evento.tickets.length > 1
                                ? evento.tickets.length < 4
                                  ? " / " + evento.tickets[1].hora_fmt
                                  : evento.tickets[evento.tickets.length - 1]
                                      .hora_fmt
                                : ""} */}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="text-2Eve montserrat-normal-thunder-22px">
                        {t("header.lugar")}
                        {evento.ubication.location}
                      </div>

                      {evento.tickets.length === 0 ? (
                        <div></div>
                      ) : (
                        <div className="text-2Eve montserrat-normal-thunder-22px">
                          {evento.tickets[0].name === "Bono" ? (
                            <div></div>
                          ) : (
                            <div>
                              {evento.tickets[0].netPrice === 0 ? (
                                <div>
                                  {t("header.precio")} {t("header.gratis")}
                                </div>
                              ) : (
                                <div>
                                  {evento.name === "Melodic Rock Festival: The Comeback!" ?
                                    (
                                      <>
                                        {t("header.precio")} 36 {"€"}
                                      </>
                                    ) : (
                                      <>
                                        {t("header.precio")} {evento.tickets[0].netPrice}
                                        {"€"}</>)}

                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </div>
                )}
              </div>
            </Row>
          </div>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default CartaEvento;
