import "./index.css";
import "./styleguide.css";
import "./global.css";
import "bootstrap/dist/css/bootstrap.css";

import App from "./App";
import { I18nextProvider } from "react-i18next";
import React from "react";
import ReactDOM from "react-dom";
import UserContextProvider from "./components/context/userContext";
import global_en from "./translations/en/global.json";
import global_es from "./translations/es/global.json";
import global_eus from "./translations/eus/global.json";
import i18next from "i18next";
import reportWebVitals from "./reportWebVitals";

require("dotenv").config();

i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources: {
    es: {
      global: global_es,
    },
    eus: {
      global: global_eus,
    },
    en: {
      global: global_en,
    },
  },
});

ReactDOM.render(
  <UserContextProvider>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </UserContextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
