/* eslint-disable no-unused-vars */

import "../../styles/styles.css";

import {
  Alert,
  Button,
  Col,
  Modal,
  Nav,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { getDiaEvento, getHoraFmt } from "./../../utils/fechas";

import AccountCircle from "@material-ui/icons/AccountCircle";
import CartaEvento from "./../CartaEvento/CartaEvento";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import PasswordIcon from "@material-ui/icons/Lock";
import PeopleIcon from "@material-ui/icons/AccountCircle";
import TextField from "@material-ui/core/TextField";
import { UserContext } from "./../context/userContext";
import UserIcon from "@material-ui/icons/PeopleAlt";
import authApi from "./../../services/authApi";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Calendario({ eventos_ciclo, ciclo }) {
  //console.log(eventos_ciclo);

  const [eventos_julio, setEventosJulio] = useState([]);
  const [eventos_agosto, setEventosAgosto] = useState([]);
  const [mes, setMes] = useState("");
  const [nav, setNav] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [loading, setLoading] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isBonds, setIsbonds] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  let history = useHistory();

  const [estado, setEstado] = useState("");
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const { isAuth, setIsAuth, user, setUser, pass, setPass } =
    useContext(UserContext);

  const handleLogin = async () => {
    setLoadingLogin(true);
    const payload = {
      username: form.username,
      password: form.password,
    };
    if (payload.username === "" || payload.password === "") {
      setEstado(t("header.campos_vacios"));
      setLoadingLogin(false);
    } else {
      authApi
        .iniciarSesion(payload.username, payload.password)
        .then((res) => {
          setIsAuth(true);
          setUser(payload.username);
          setPass(payload.password);
          setLoadingLogin(false);
          history.push({
            pathname: "/canjeBonos",
            state: { eventos_ciclo: eventos_ciclo, info: res },
          });
        })
        .catch((error) => {
          setEstado(t("header.error_login"));
          setForm({
            username: "",
            password: "",
          });
          setLoadingLogin(false);
        });
    }
  };

  const Login = async () => {
    authApi.iniciarSesion(user, pass).then((res) => {
      history.push({
        pathname: "/canjeBonos",
        state: { eventos_ciclo: eventos_ciclo, info: res },
      });
    });
  };

  const onChangePass = (e) => {
    setForm({ ...form, password: e.target.value });
  };
  const onChangeUser = (e) => {
    setForm({ ...form, username: e.target.value });
  };

  if (ciclo.description !== "") {
    var parrafoEspañol = ciclo.description;
  } else {
    parrafoEspañol = [];
  }

  useEffect(() => {
    eventos_julio.length = 0;
    eventos_agosto.length = 0;
    eventos_ciclo.forEach((element) => {
      element.tickets.forEach((element) => {
        element.hora_fmt = getHoraFmt(element.startDate);
      });
      element.dia_fmt = getDiaEvento(element.startDate);
      element.dia_fmt = getDiaEvento(element.startDate);
      let minutes;
      let hora = new Date(element.startDate).getHours();
      if (new Date(element.startDate).getMinutes() < 10) {
        minutes = "0" + new Date(element.startDate).getMinutes();
      } else {
        minutes = new Date(element.startDate).getMinutes();
      }
      element.hora_fmt = hora + ":" + minutes;
      eventos_julio.push(element);
    });

   /*  eventos_ciclo.forEach((element) => {
      if (element.startDate.includes("2022-03")) {
        element.tickets.forEach((element) => {
          element.hora_fmt = getHoraFmt(element.startDate);
        });
        element.dia_fmt = getDiaEvento(element.startDate);
        element.dia_fmt = getDiaEvento(element.startDate);
        let minutes;
        let hora = new Date(element.startDate).getHours();
        if (new Date(element.startDate).getMinutes() < 10) {
          minutes = "0" + new Date(element.startDate).getMinutes();
        } else {
          minutes = new Date(element.startDate).getMinutes();
        }
        element.hora_fmt = hora + ":" + minutes;
        eventos_julio.push(element);
      }
      if (element.startDate.includes("2022-04")) {
        element.tickets.forEach((element) => {
          element.hora_fmt = getHoraFmt(element.startDate);
        });
        element.dia_fmt = getDiaEvento(element.startDate);
        element.dia_fmt = getDiaEvento(element.startDate);
        let minutes;
        let hora = new Date(element.startDate).getHours();
        if (new Date(element.startDate).getMinutes() < 10) {
          minutes = "0" + new Date(element.startDate).getMinutes();
        } else {
          minutes = new Date(element.startDate).getMinutes();
        }
        element.hora_fmt = hora + ":" + minutes;
        eventos_agosto.push(element);
      }
    }); */

    if (!nav) {
      if (eventos_julio.length > 0) {
        setMes("marzo");
      } else if (eventos_agosto.length > 0) {
        setMes("abril");
      }
    }

    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Tab.Container defaultActiveKey={mes}>
          <div>
            {/* 
            {i18n.language === "es" ? (
              <div>
                {parrafoEspañol !== null ? (
                  <Row
                    style={{
                      marginTop: "50px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    {parrafoEspañol.map((element, key) => {
                      return (
                        <ul key={key} className="parrafoIndicacion2">
                          {element}
                        </ul>
                      );
                    })}
                  </Row>
                ) : (
                  <></>
                )}
                
                <Row xs={2} lg={1} style={{ justifyContent: "center" }}>
                  <h2
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      marginTop: "50px",
                      textAlign: "center",
                      fontSize: "22px",
                    }}
                  >
                    {t("header.infoCovid1")}
                  </h2>
                </Row>

                <Row
                  xs={2}
                  lg={1}
                  style={{
                    justifyContent: "center",
                    marginTop: "30px",
                    textAlign: "center",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {t("header.infoCovid2")}
                  </h4>
                </Row>

                <Row
                  xs={2}
                  lg={1}
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {t("header.infoCovid3")}
                  </h4>
                </Row>

                <Row
                  xs={2}
                  lg={1}
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {t("header.infoCovid4")}
                  </h4>
                </Row> 

                <Row
                  style={{
                    marginTop: "50px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {ciclo.name === "BAD" ? (
                    <div style={{ display: "flex" }}>
                     
                      <a href="https://eventos.ontourup.com/event/c0a78c7d-52aa-499d-951b-c18ecab3dde7/tickets">
                        <Button
                          style={{ marginLeft: "auto" }}
                          variant="primary"
                        >
                          {t("header.Compra_bonos")}
                        </Button>
                      </a>
                      {isAuth ? (
                        <Button
                          onClick={Login}
                          style={{
                            marginLeft: "40px",
                            marginRight: "auto",
                            backgroundColor: "white",
                            color: "black",
                          }}
                          variant="primary"
                        >
                          {t("header.Canje_bonos")}
                        </Button>
                      ) : (
                        <div>
                          <Button
                            onClick={handleShow}
                            style={{
                              marginLeft: "40px",
                              marginRight: "auto",
                              backgroundColor: "white",
                              color: "black",
                            }}
                            variant="primary"
                          >
                            {t("header.Canje_bonos")}
                          </Button>
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>
                                {t("header.Inicio_sesion")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {
                                <div
                                  style={{
                                    justifyContent: "center",
                                    display: "grid",
                                  }}
                                >
                                  <Grid
                                    style={{
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                    item
                                  >
                                    <UserIcon style={{ fontSize: "4.5rem" }} />
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item>
                                      <TextField
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <AccountCircle />
                                            </InputAdornment>
                                          ),
                                        }}
                                        fullWidth
                                        autoFocus
                                        label={t("header.Correo_electronico")}
                                        margin="normal"
                                        name="email"
                                        type="email"
                                        value={form.username}
                                        variant="outlined"
                                        onChange={onChangeUser}
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={1}>
                                    <Grid item>
                                      <TextField
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <PasswordIcon />
                                            </InputAdornment>
                                          ),
                                        }}
                                        fullWidth
                                        autoFocus
                                        label={t("header.contrasena")}
                                        margin="normal"
                                        name="password"
                                        type="password"
                                        value={form.password}
                                        variant="outlined"
                                        onChange={onChangePass}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Alert>{estado}</Alert>
                                  </Grid>
                                </div>
                              }
                            </Modal.Body>
                            <Modal.Footer style={{ justifyContent: "center" }}>
                              <Button
                                variant="primary"
                                disabled={loadingLogin}
                                onClick={handleLogin}
                              >
                                {t("header.Ingresar")}
                              </Button>
                              {loadingLogin ? (
                                <Spinner animation="border" />
                              ) : (
                                ""
                              )}
                            </Modal.Footer>
                          </Modal>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Row>

              </div>
            ) : (
              <div>

                <Row
                  style={{
                    marginTop: "50px",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  {parrafoEuskera.map((element, key) => {
                    return (
                      <ul key={key} className="parrafoIndicacion2">
                        {element}
                      </ul>
                    );
                  })}
                </Row>

              <Row xs={2} lg={1} style={{ justifyContent: "center" }}>
                  <h2
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      marginTop: "50px",
                      textAlign: "center",
                      fontSize: "22px",
                    }}
                  >
                    {t("header.infoCovid1")}
                  </h2>
                </Row>

                <Row
                  xs={2}
                  lg={1}
                  style={{
                    justifyContent: "center",
                    marginTop: "30px",
                    textAlign: "center",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {t("header.infoCovid2")}
                  </h4>
                </Row>

                <Row
                  xs={2}
                  lg={1}
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {t("header.infoCovid3")}
                  </h4>
                </Row>

                <Row
                  xs={2}
                  lg={1}
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {t("header.infoCovid4")}
                  </h4>
                </Row>

                <Row
                  style={{
                    marginTop: "50px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {eventos_ciclo[0].tags[0] === "BAD" ? (
                    <div style={{ display: "flex" }}>
                      
                      <a href="https://eventos.ontourup.com/event/c0a78c7d-52aa-499d-951b-c18ecab3dde7/tickets">
                        <Button
                          style={{ marginLeft: "auto" }}
                          variant="primary"
                        >
                          {t("header.Compra_bonos")}
                        </Button>
                      </a>
                      {isAuth ? (
                        <Button
                          onClick={Login}
                          style={{
                            marginLeft: "40px",
                            marginRight: "auto",
                            backgroundColor: "white",
                            color: "black",
                          }}
                          variant="primary"
                        >
                          {t("header.Canje_bonos")}
                        </Button>
                      ) : (
                        <div>
                          <Button
                            onClick={handleShow}
                            style={{
                              marginLeft: "40px",
                              marginRight: "auto",
                              backgroundColor: "white",
                              color: "black",
                            }}
                            variant="primary"
                          >
                            {t("header.Canje_bonos")}
                          </Button>
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>
                                {t("header.iniciar-sesion")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {
                                <div
                                  style={{
                                    justifyContent: "center",
                                    display: "grid",
                                  }}
                                >
                                  <Grid
                                    style={{
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                    item
                                  >
                                    <UserIcon style={{ fontSize: "4.5rem" }} />
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item>
                                      <TextField
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <AccountCircle />
                                            </InputAdornment>
                                          ),
                                        }}
                                        fullWidth
                                        autoFocus
                                        label={t("header.Correo_electronico")}
                                        margin="normal"
                                        name="email"
                                        type="email"
                                        value={form.username}
                                        variant="outlined"
                                        onChange={onChangeUser}
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={1}>
                                    <Grid item>
                                      <TextField
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <PasswordIcon />
                                            </InputAdornment>
                                          ),
                                        }}
                                        fullWidth
                                        autoFocus
                                        label={t("header.contrasena")}
                                        margin="normal"
                                        name="password"
                                        type="password"
                                        value={form.password}
                                        variant="outlined"
                                        onChange={onChangePass}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Alert>{estado}</Alert>
                                  </Grid>
                                </div>
                              }
                            </Modal.Body>
                            <Modal.Footer style={{ justifyContent: "center" }}>
                              <Button
                                variant="primary"
                                disabled={loading}
                                onClick={handleLogin}
                              >
                                {t("header.Ingresar")}
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Row>
              </div>
            )} 
            */}

            <Row
              style={{ marginLeft: "0", marginRight: "0", marginTop: "73px" }}
              xs={1}
              xl={8}
            >
              
                {/* <div className="selecion-mesCal" style={{ padding: "20px" }}>
                  <h1 className="calendario-eventos text-wrap">
                    {t("header.calendario-eventos")}
                  </h1>
                </div> */}
             
              {/*<Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <div className="mesesCal">
                   <Nav
                    variant="tabs"
                    defaultActiveKey={mes}
                    onSelect={(selectedKey) => {
                      switch (selectedKey) {
                        case "marzo":
                          setLoading(true);
                          setNav(true);
                          setMes("marzo");
                          break;
                        case "abril":
                          setLoading(true);
                          setNav(true);
                          setMes("abril");
                          break;

                        default:
                          break;
                      }
                    }}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="marzo">
                        {t("header.marzo")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="abril">{t("header.abril")}</Nav.Link>
                    </Nav.Item>
                  </Nav> 
                </div>
              </Col>*/}
            </Row>
          </div>

          <div
            className="row row-cols-lg-10 row-cols-1"
            style={{ marginLeft: "0", marginBottom: "150px" }}
          >
            {eventos_ciclo.length !== 0 ? (
              <Tab.Content>
                <Tab.Pane eventKey="marzo">
                  <Row style={{ justifyContent: "center" }}>
                    <Row
                      style={{
                        justifyContent: "center",
                        marginLeft: "0px",
                        marginRight: "0px",
                      }}
                    >
                      {eventos_julio.map((data, i) => {
                        return <CartaEvento key={i} evento={data} bono={""} />;
                      })}
                    </Row>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="abril">
                  <Row
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Row
                      style={{
                        justifyContent: "center",
                        marginLeft: "0px",
                        marginRight: "0px",
                      }}
                    >
                      {eventos_agosto.map((data, i) => {
                        return <CartaEvento key={i} evento={data} bono={""} />;
                      })}
                    </Row>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            ) : (
              <div></div>
            )}
          </div>
        </Tab.Container>
      )}
    </div>
  );
}

export default Calendario;
