import React, { createContext, useState, useMemo } from "react";

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState();
  const [pass, setPass] = useState();
  //console.log(isAuth);

  const values = useMemo(() => ({ isAuth, setIsAuth,user, setUser,pass, setPass}), [
    isAuth,
    user,
    pass
  ]);

  return (
    <UserContext.Provider value={values}>{props.children}</UserContext.Provider>
  );
};

export default UserContextProvider;

