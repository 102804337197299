import "./../utils/fechas";

import api from "./index";

class EntrnacesService {
  async getEntrancesUser(accountId) {
    const res = await api.get(
      "/entrance/getEntrancesAccount?accountID=" + accountId
    );
    return res.data;
  }

  async checkBonos(tickets, accountID){
      let bonos = []
      const res = await this.getEntrancesUser(accountID)
      //console.log(res)
      res.data.forEach(element => {          
          if (element.ticket === tickets[0] || element.ticket === tickets[1]){
              bonos.push(element)
          }
      });

      return bonos
  }

  async updateEntrance(entrance_id, numb_used){
    //console.log(numb_used)
    const res = await api.patch("/entrance/"+entrance_id, {"number_used": numb_used})

    return res
  }
}

export default new EntrnacesService();
