import { Col, Container, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";

import Calendario from "../../components/calendario/calendario";
import SelectorIdioma from "../../components/SelectorIdioma/SelectorIdioma";
import ciclosService from "../../services/ciclos";
import eventosService from "../../services/eventos";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
/* import logo1 from "../../assets/DreamingD.jpg";
import logo2 from "../../assets/Logo1.jpeg"; */
import banner from "../../assets/Cabecera_DreamingTickets.png";
import "../../styles/styles.css";
import { Helmet } from "react-helmet";

function ListaEventos({ ciclo_key }) {
  const [t] = useTranslation("global");
  const [loading, setloading] = useState(true);
  const [eventos, seteventos] = useState();

  const [ciclo, setciclo] = useState();
  const history = useHistory();
  const [ciclos, setCiclos] = useState();

  useEffect(() => {
    async function getEventsCiclos() {
      const ciclos_temp = await ciclosService.getCiclos();
      setCiclos(ciclos_temp);
      for (const ciclo_temp of ciclos_temp) {
        if (ciclo_temp.tags[1] === ciclo_key) {
          setciclo(ciclo_temp);
          await eventosService.getEventsCiclo(ciclo_temp.name).then((res) => {
            seteventos(res);
            if (loading) {
              setloading(false);
            }
          });
        }
      }
    }

    getEventsCiclos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ciclo_key]);

  return (
    <Container fluid className="home2">
      <Helmet>
        <meta charset="utf-8" />
        <title>Dreaming Tickets - Entradas</title>
        <meta
          name="description"
          content="Dreaming Tickets te brinda los mejores eventos en España, comprometidos con el arte de calidad, la mejor experiencia de la audiencia y queriendo innovar en la experiencia de los mejores directos, somos parte del cambio, parte de la nueva y mejor Industria Musical, ¿nos ayudas a seguir propagando arte y cultura de calidad?"
        />
        <link
          rel="canonical"
          href="https://dreamingticket.web.app/ciclo/entradas"
        />
      </Helmet>
      <Row style={{ height: "50px" }}>
        <Col
          style={{
            position: "absolute",
            right: "0%",
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SelectorIdioma />
        </Col>
      </Row>
      {/* <Row style={{ height: "73px", width: "93%" }}>
        <Col
          xs={3}
          style={{
            marginRight: "30px",
          }}
        >
          <SelectorIdioma />
        </Col>
         <Col
          xs={7}
          style={{
            justifyContent: "flex-end",
            display: "flex",
            marginLeft: "auto",
          }}
        >
          <Col sm={1} xl={4}>
            <img style={{ width: "200px" }} src={logo1} alt="banner" />
          </Col>
          <Col
            xs={3}
            style={{ padding: "0", marginLeft: "10px", marginRight: "-22px" }}
          >
            <img style={{ width: "235px" }} src={logo2} alt="banner" />
          </Col>
        </Col>  
      </Row>*/}

      <Row className="rowBanner">
        <img className="imgBanner" style={{width:"100%"}} src={banner} alt="banner"/>
      </Row>

      <Row id="alpha">
        <Row id="alpha_2">
          <div style={{ marginTop: "-12px" }} className="selectorHome">
            <div className="flex-row-Home">
              <div className="flex-colHome">
                <div className="selecciona-el-cicloHome">
                  {t("header.Seleciona-el-ciclo")}
                </div>
              </div>
              {loading === true ? (
                <div></div>
              ) : (
                <Nav
                  style={{
                    justifyContent: "center",
                    backgroundColor: "black",
                    borderBottom: "black",
                  }}
                  variant="tabs"
                  defaultActiveKey={ciclo_key}
                  onSelect={(selectedKey) => {
                    for (const ciclo of ciclos) {
                      if (selectedKey === ciclo.tags[1]) {
                        setloading(true);
                        setciclo(ciclo);
                        //console.log(ciclo.name);
                      }
                    }
                    history.push(selectedKey);
                  }}
                >
                  {ciclos.map((data, key) => {
                    return (
                      <Nav.Item style={{ backgroundColor: "black" }} key={key}>
                        {/* {ciclo_key === data.tags[1] ? (
                        <Nav.Link disabled eventKey={data.tags[1]}>{data.name}</Nav.Link>
                      ):(
                        <Nav.Link eventKey={data.tags[1]}>{data.name}</Nav.Link>
                      )} */}
                      </Nav.Item>
                    );
                  })}
                </Nav>
              )}
            </div>
          </div>
        </Row>
        <Row
          style={{
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Tab.Container defaultActiveKey="musikauzo">
            <Tab.Content style={{ width: "100%" }}>
              <Tab.Pane eventKey="musikauzo">
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
                )}{" "}
              </Tab.Pane>

              {/* <Tab.Pane eventKey="eszena-kalera">
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
                )}{" "}
              </Tab.Pane>
              <Tab.Pane eventKey="clown-zorrotza">
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
              )}{" "}
            </Tab.Pane>
              <Tab.Pane eventKey="magiaren-topaketa">
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
              )}{" "}
            </Tab.Pane>
              <Tab.Pane eventKey="Bertso Saioak">
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
                )}{" "}
              </Tab.Pane>
              <Tab.Pane eventKey="zirkualde">
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
                )}{" "}
              </Tab.Pane> */}
            </Tab.Content>
          </Tab.Container>
        </Row>
      </Row>
    </Container>
  );
}

export default ListaEventos;
