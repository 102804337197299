import axios from "axios";

class authApi {
  async iniciarSesion(username, password) {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_BACK_NEXT+"/auth/login/client",
      data: {
        email: username,
        password: password,
      },
    })
    return res.data.data.account;
  }
}

export default new authApi();
