import "./../utils/fechas";

import api from "./index";

class CiclosService {
  async getCiclos() {
    //console.log(process.env)
    const ciclos = [];
    const res = await api.get(
      "/event?tags=ciclo"
    );
    for (const data of res.data.data) {
      if (data.isVisible === true && data.promoter === "62431580216e2c37d1cf036c" && new Date(data.startDate).getTime() <= new Date().getTime() &&
      new Date(data.endDate).getTime() >= new Date().getTime()) {
        ciclos.push(data);
      }
    }
    
    return ciclos;
  }
}

export default new CiclosService();
