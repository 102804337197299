import "./App.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { Container } from "react-bootstrap";
import Evento from "./pages/event/event";
import Home from "./pages/home/Home";
import CanjeBonos from "./components/Bonos/canjeBonos";
import ListaEventos from "./pages/listaEventos/listaEventos";
/* import fondo from "./assets/Background_DreamingDreams.png"; */
//import Footer from './components/Footer/footer';
//, backgroundImage: `url(${fondo})`,backgroundSize:"cover"
import { Helmet } from "react-helmet";
import favicon from "./assets/Dreaming_TicketSquare_black_logo.ico";

function App() {
  return (
    <Container fluid style={{ paddingLeft: "0", paddingRight: "0" }}>
      <Helmet>
        <meta charset="utf-8" />ç
        <meta name="google-site-verification" content="WpjIxK4Sk3fFkX9jL81RUHqe33wiL7K95JMmK08WKWs" />
        <title>Dreaming Tickets</title>
        <meta property="og:title" content="Dreaming Tickets" />
        <meta name="description" content="Dreaming Tickets te brinda los mejores eventos en España, comprometidos con el arte de calidad, la mejor experiencia de la audiencia y queriendo innovar en la experiencia de los mejores directos, somos parte del cambio, parte de la nueva y mejor Industria Musical, ¿nos ayudas a seguir propagando arte y cultura de calidad?" />
        <link rel="icon" href={favicon} />
        <link rel="canonical" href="https://dreamingticket.web.app" />
      </Helmet>
      <Router>
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route
            path="/ciclo/:ciclo"
            render={({ match }) => {
              return <ListaEventos ciclo_key={match.params.ciclo} />;
            }}
          />
          <Route
            path="/evento/:eventoId"
            render={({ match }) => <Evento eventoID={match.params.eventoId} />}
          />
          <Route path="/canjeBonos" component={CanjeBonos} />
        </Switch>
      </Router>
    </Container>
  );
}

export default App;
