import "../../styles/styles.css";

import { Card, Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SelectorIdioma from "../../components/SelectorIdioma/SelectorIdioma";
import banner from "../../assets/Cabecera_DreamingTickets.png";
import ciclosService from "../../services/ciclos";
/* import logo1 from "../../assets/DreamingD.jpg";
import logo2 from "../../assets/Logo1.jpeg"; */
import { useTranslation } from "react-i18next";

function Home() {
  const [t] = useTranslation("global");
  const [loading, setloading] = useState(true);
  const [ciclos, setCiclos] = useState([]);

  useEffect(() => {
    async function getCiclos() {
      let ciclo = await ciclosService.getCiclos();

      setCiclos(ciclo);
      if (loading) {
        setloading(false);
      }
    }
    getCiclos();
  }, [loading]);
  return (
    <Container fluid className="home">
      {loading === true ? (
        <div></div>
      ) : (
        <div className="fondoHome">
          <Helmet>
            <meta charset="utf-8" />
            <meta property="og:title" content="Dreaming Tickets" />
            <title>Dreaming Tickets</title>
            <meta
              name="description"
              content="Dreaming Tickets te brinda los mejores eventos en España, comprometidos con el arte de calidad, la mejor experiencia de la audiencia y queriendo innovar en la experiencia de los mejores directos, somos parte del cambio, parte de la nueva y mejor Industria Musical, ¿nos ayudas a seguir propagando arte y cultura de calidad?"
            />
            <link rel="canonical" href="https://dreamingtickets.com" />
          </Helmet>
          <Row style={{ height: "35px" }}>
            <Col
              style={{
                position: "absolute",
                right: "0%",
                justifyContent: "flex-start",
                display: "flex",
                alignItems: "center",
                backgroundColor: "black",
              }}
            >
              <SelectorIdioma />
            </Col>
          </Row>

          <Row className="rowBanner">
            <img
              alt="banner"
              src={banner}
              className="imgBanner"          
            />
          </Row>
          <Row
            style={{
              width: "40%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "-11px",
              justifyContent: "center",
            }}
          >
            <div className="selectorHome">
              <div className="selecciona-el-cicloHome">{t("header.title")}</div>
            </div>
          </Row>
          <div className="containerIndicaHome">
            <div>
              <h2
                style={{
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "22px",
                  color: "white",
                }}
              >
                {t("header.texto-dreaming")}
              </h2>
            </div>
            <div>
              <h2
                style={{
                  display: "flex",
                  marginTop: "25px",
                  fontSize: "22px",
                  color: "white",
                }}
              >
                {t("header.texto-dreaming2")}
              </h2>
            </div>
            <div>
              <h2
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "25px",
                  fontSize: "22px",
                  color: "white",
                }}
              >
                {t("header.texto-dreaming3")}
              </h2>
            </div>
            <div>
              <h2
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "25px",
                  fontSize: "22px",
                  color: "white",
                }}
              >
                {t("header.texto-dreaming4")}
              </h2>
            </div>
          </div>
          {loading ? (
            <div></div>
          ) : (
            <Row style={{ marginTop: "60px", marginBottom: "100px" }}>
              {ciclos.map((data, key) => {
                return (
                  <Col
                    key={key}
                    style={{
                      margin: "30px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    sm={true}
                    md={true}
                  >
                    <Link to={"ciclo/" + data.tags[1]}>
                      <Card
                        style={{
                          borderRadius: "10px",
                          boxShadow: "6px 6px 18px #00000029",
                          minWidth: "300px",
                          maxWidth: "300px",
                        }}
                      >
                        <Card.Img variant="top" src={data.banner} alt="imagenCarta" />
                        <Card.Body>
                          <Card.Title
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "2rem",
                            }}
                          >
                            {data.name}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      )}
    </Container>
  );
}

export default Home;
