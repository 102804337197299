import "./infoEvento.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Col, Row, Button } from "react-bootstrap";

import { Carousel } from "react-responsive-carousel";
import GoogleMaps from "simple-react-google-maps";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

function InfoEvento({ evento, tickets }) {
  /* console.log(evento); */
  var longitud = parseFloat(evento.ubication.gps.longitude);
  var latitud = parseFloat(evento.ubication.gps.latitude);
  const [t, i18n] = useTranslation("global");
  const [agotado, setAgotado] = useState(true);
  var descripcionEs = evento.description ? evento.description : "";
  const eventoData = {
    iconMetroLocation:
      "https://anima-uploads.s3.amazonaws.com/projects/608138be200c27f99805dc8c/releases/60828d4c841fac091e8381dd/img/icon-metro-location@1x.png",
    overlapGroup13:
      "https://anima-uploads.s3.amazonaws.com/projects/608138be200c27f99805dc8c/releases/60828d4c841fac091e8381dd/img/rectangle-166@1x.png",
  };
  if (evento.description !== "") {
    var arrayDeCadenas = evento.description.split("#");
    var descripcionIng = arrayDeCadenas[0].split("\n");
    var descripcionEs = arrayDeCadenas[1].split("\n");
  } else {
    arrayDeCadenas = [];
    descripcionIng = [];
    descripcionEs = [];
  }
  useEffect(() => {
    for (const ticket of tickets) {
      if (ticket.amount > ticket.sellTickets) {
        setAgotado(false);
      }
    }
  }, []);

  return (
    <div style={{ backgroundColor: "black" }}>
      <Row>
        <Col style={{ marginBottom: "50px" }}>
          <div className="descripcin montserrat-medium-thunder-25px">
            {t("header.descripcion")}
          </div>
          <div className="la-cantante-y-compos">
            {i18n.language === "es" ? (
              <div style={{ justifyContent: "center" }}>
                {descripcionEs.map((data, key) => {
                  return (
                    <div key={key}>
                      <Row>{data}</Row>
                      <br></br>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {descripcionIng.map((data, key) => {
                  return (
                    <div key={key}>
                      <Row>{data}</Row>
                      <br></br>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {evento.descripcionEs > 500 || evento.descripcionIng > 500 ? (
            <></>
          ) : (
            <div className="buttonnormal">
              <div>
                {tickets[0].grossPrice > 0 ? (
                  <>
                    {agotado ? (
                      <Button
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "white",
                          borderColor: "gray",
                          color: "black",
                        }}
                        disabled
                      >
                        {t("header.agotada")}
                      </Button>
                    ) : (
                      <>
                        {evento.tags[0] === "Gau magikoa / La noche mágica" ? (
                          <Link
                            to={{
                              pathname:
                                "https://eventos.bilbao.ontourup.com/event/" +
                                evento.evento.qr +
                                "/tickets",
                            }}
                            target="_blank"
                            replace
                          >
                            <div
                              className="overlap-group1-2"
                              style={{
                                backgroundImage: `url(${eventoData.overlapGroup13})`,
                              }}
                            >
                              <div className="reservar-entradas">
                                {t("header.compra-entradas")}
                              </div>
                            </div>
                          </Link>
                        ) : (
                          <Link
                            to={{
                              pathname:
                                "https://eventos.ontourup.com/event/" +
                                evento.qr +
                                "/tickets",
                            }}
                            target="_blank"
                          >
                            <div
                              className="overlap-group1-2"
                              style={{
                                backgroundImage: `url(${eventoData.overlapGroup13})`,
                              }}
                            >
                              <div className="reservar-entradas">
                                {t("header.compra-entradas")}
                              </div>
                            </div>
                          </Link>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {agotado ? (
                      <Button
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "white",
                          borderColor: "gray",
                          color: "black",
                        }}
                        disabled
                      >
                        {t("header.mensaje-agotado")}
                      </Button>
                    ) : (
                      <Link to={evento.evento._id + "/entradas"}>
                        <div
                          className="overlap-group1-2"
                          style={{
                            backgroundImage: `url(${eventoData.overlapGroup13})`,
                          }}
                        >
                          <div
                            className="reservar-entradas"
                            style={{ lineHeight: "35px" }}
                          >
                            {t("header.reserva-entradas")}
                          </div>
                        </div>
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          {evento.url.includes("youtube") || evento.url.includes("vimeo") ? (
            <div>
              <div className="descripcin" style={{ marginTop: "50px" }}>
                {" "}
                {t("header.video")}
              </div>

              <ReactPlayer
                style={{ marginTop: "50px" }}
                url={evento.url}
                className="react-player"
                playing={false}
                height="360px"
                width="auto"
                controls
              />
            </div>
          ) : (
            <div></div>
          )}
        </Col>

        <Col className="colmDer">
          {/* <div className="overlap-group1">
            <Row>
              <Col>
                <h5 style={{fontSize:"1.5rem"}}>
                  ESTILO
                </h5>
                <p>
                  vintage Rock, Hard-Rock,Musica de lo 60`s y 70`s, Female Rock
                </p>
              </Col>
            </Row>
            <Row style={{marginTop:"30px"}}>
              <Col>
                <h5 style={{fontSize:"1.5rem"}}>
                  TE GUSTARÁN SI ESCUCHAS
                </h5>
                <p>Led Zeppelin, Greta Van Fleet...</p>
              </Col>
            </Row>
          </div> */}

          <div className="ubicacionEven">
            <div style={{ width: "100%" }}>
              <h5 className="ubicacinEven montserrat-medium-thunder-25px">
                {t("header.ubicacion")}
              </h5>
              <div className="fondoMap">
                <GoogleMaps                
                  apiKey={"AIzaSyCEpRrgB2-1d7Ph48KbvZmK6o7nImqSm4Q"}
                  // apiKey={"AIzaSyCy7NFqmPnE7N8WG2xerQKcJ2ArSO9Gl5A"}
                  zoom={15}
                  center={{
                    lat: latitud,
                    lng: longitud,
                  }}
                  markers={[{ lat: latitud, lng: longitud }]}
                />
              </div>
              {evento.ubication.location === undefined ? (
                <h6 className="text-3Even">{evento.location}</h6>
              ) : (
                <h6 className="text-3Even">{evento.ubication.location}</h6>
              )}
              <p className="text-DirEven">
                {evento.ubication.address}, {evento.ubication.postalCode}.{" "}
              </p>
              {evento.name === "THE DAMN TRUTH" &&
              evento.ubication.city === "Madrid" ? (
                <p className="text-DirEven">
                  Bilbao (L1,L4), San Bernardo (L2), Alonso Martinez (L5,L10)
                </p>
              ) : (
                <>
                  {evento.name === "THE DAMN TRUTH" &&
                  evento.ubication.city === "Bilbo" ? (
                    <p className="text-DirEven">Bus: A3411</p>
                  ) : (
                    <>
                      {evento.name === "Melodic Rock Festival: The Comeback!" &&
                      evento.ubication.city === "Madrid" ? (
                        <p className="text-DirEven">
                          Metro: L7 (Parque de las Avenidas). L2, L5 (Ventas)
                        </p>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}

              {/* <p className="text-DirEven">
                {evento.ubication.city}
                {", "}
                {evento.ubication.province}
              </p> */}
            </div>
          </div>
          <div>
            {evento.galery != null ? (
              <>
                {evento.galery.length > 0 ? (
                  <>
                    <h5
                      style={{ color: "white" }}
                      className="ubicacinEven montserrat-medium-thunder-25px"
                    >
                      {t("header.galeria")}
                    </h5>
                    <div className="backgroundGallery">
                      <Carousel showArrows={true}>
                        {evento.galery.map((data, key) => {
                          return (
                            <div key={key}>
                              <img src={data} alt="" />
                            </div>
                          );
                        })}
                      </Carousel>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default InfoEvento;
