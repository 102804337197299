import "./../utils/fechas";

import TicketsService from "./tickets";
import api from "./index";

class EventosService {
  async getEventsPromoter() {
    const res = await api.get(
      "dashboard/promoter/events?_idPromoter=62431580216e2c37d1cf036c"
    );
    return res.data.data;
  }

  async getEventsCiclo(ciclo) {
    const eventos_ciclo = [];
    const res = await api.get(
      "/event/thumbnail-info?isWebVisible=true&tags=" + ciclo
    );

    for (const data of res.data.data) {
      if (
        new Date(data.endDate).getTime() >= new Date().getTime()
      ) {

        eventos_ciclo.push(data);
      }
    }

    eventos_ciclo.sort(function (a, b) {
      return new Date(a.startDate) - new Date(b.startDate);
    })


    return eventos_ciclo;
  }

  async getEvento(eventoID) {
    let datos_evento = null;
    let datos_entradas = [];

    //Comentar en local
/*     const res = await api.get(
      "/event?_id=" + eventoID
    ); */
      //console.log(res.data.data)
    //Comentar al subir
    const res = await api.get(
      "/event/" + eventoID
    );
    //const res = await this.getEventsPromoter()
    const res_tickets = await TicketsService.getTickets(eventoID);

    datos_evento = res.data.data;

    datos_entradas = res_tickets.results;
    /* res_tickets.results.forEach((data) => {
      datos_entradas = data;
    }); */
    datos_entradas.sort(function (a, b) {
      return new Date(a.startDate) - new Date(b.startDate);
    })
    const evento = { evento: datos_evento, tickets: datos_entradas };
    return evento;
  }
}

export default new EventosService();
